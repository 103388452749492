<template>
    <div class="comp-root" :class="{ 'comp-is-loading': !this.compLoaded }">
        <div class="row mb-3">
            <div class="col-6">
                <button class="btn btn-sm btn-primary" @click="onPrintClick" print-hide>
                    <i class="fas fa-print"></i> Drucken
                </button>
            </div>
            <div class="col-6 text-right">
                <div class="input-group input-group-sm" print-hide>
                    <div class="input-group-prepend">
                        <span class="input-group-text">Machine:</span>
                    </div>

                    <select v-model.number='machineFilter' class="form-control">
                        <option value="0">Alle</option>
                        <option v-for='machine in $store.state.machines' :value='machine.id'>
                            {{ machine.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-center" style="border: 1px solid black;">
                <h1>Kalibrier- und Wartungsarbeiten {{ currentMonthAndYear }}</h1>
            </div>
        </div>

        <div class="row">
            <table class="table table-hover table-striped" style="width: 100%;">
                <thead class="thead-dark">
                    <tr>
                        <th class="align-top" print-width-one>Maschine</th>
                        <!-- <span print-hide>   </span> -->
                        <th class="align-top" print-width-two>Ger&auml;te-Nr.<br />Registrier-Nr.</th>
                        <th class="align-top" print-width-three>Standort</th>
                        <th class="align-top" print-width-four>Pr&uuml;fer</th>
                        <th class="align-top" print-width-five>F&auml;lligkeit</th>
                        <th class="align-top" print-width-five>Wartungsarbeiten</th>
                    </tr>
                </thead>
                <tbody v-show="this.maintenances.length === 0">
                    <tr>
                        <td colspan="6">
                            Es gibt <b>keine</b> anstehenden Wartungsarbeiten!
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr v-for="singleMaintenance in this.maintenances">
                        <td>{{ singleMaintenance.machine.name }}</td>
                        <td>
                            <!--
                            <span print-hide>
                                <br />
                                {{ machine.registration_number }}
                            </span>
                            -->
                            {{ singleMaintenance.machine.device_number || 'N/A' }}
                            <br />
                            {{ singleMaintenance.machine.registration_number }}
                        </td>
                        <td>
                            {{ $store.getters.locationGetById(singleMaintenance.machine.location).name }}
                        </td>
                        <td>
                            {{ $store.getters.userGetById(singleMaintenance.machine.maintainer).name }}
                        </td>
                        <td>
                            Am
                            {{ singleMaintenance.month }}.{{ singleMaintenance.year }}
                        </td>
                        <td>
                            <div class="btn-group" print-hide>
                                <button
                                    v-if="singleMaintenance.machine.check_description !== null"
                                    class="btn btn-sm btn-primary"
                                    title="Beschreibung ansehen"
                                    @click="showCheckDescription(singleMaintenance.machine.check_description)"
                                    print-hide
                                >
                                    <i class="fas fa-info-circle" /> Ansehen
                                </button>

                                <button
                                    class="btn btn-sm btn-primary"
                                    title="Wartungsarbeiten abgeschlossen"
                                    @click="markMaintenanceAsDone(singleMaintenance.id)"
                                    print-hide
                                >
                                    <i class="fas fa-check"></i> Erledigt
                                </button>

                                <router-link print-hide class="btn btn-sm btn-success" :to="{ tag: 'button', name: 'machine_view', params: { id: singleMaintenance.machine.id } }" title="Maschinen-Karte öffnen">
                                    <i class="fas fa-toolbox"></i> Maschine
                                </router-link>
                            </div>

                            <span print-show v-html="singleMaintenance.machine.check_description"></span>
                            <span v-if="singleMaintenance.machine.check_description === null">-</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Wartungsarbeiten -->
        <div class="modal" tabindex="-1" role="dialog" ref="checkDescriptionModal">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-primary">
                        <h5 class="modal-title">Wartungsarbeiten</h5>
                        <button type="button" class="close" data-dismiss="modal" title="Schließen">
                            <span>&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" ref="checkDescriptionModalContent">
                        Inhalt
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { mapActions } from 'vuex';
import * as moment from 'moment';

export default {
    name: 'start-view',

    data() {
        return {
            // Ist die Komponente komplett geladen und bereit?
            compLoaded: false,

            // Aktuelles Datum
            currentDate: (new Date()),

            // Liste der zu wartenden Maschinen
            listOfMaintenances: null,

            // Filter Maschine
            machineFilter: 0
        };
    },

    created() {
        Promise.all([
            this.apiMachineLoadAll(true),
            this.apiLocationLoadAll(true),
            this.apiUserLoadAll(true)
        ]).then(() => {
            this.$http.get('machine/upcoming-maintenances').then(result => {
                this.listOfMaintenances = result.data;

                this.compLoaded = true;
            }).catch(error => {
                // Fehler
                this.$toast.open({
                    message: 'Fehler beim laden der Daten!',
                    type: 'error'
                });
            });

        }).catch(() => {
            // Fehler
            this.$toast.open({
                message: 'Fehler beim laden der Daten!',
                type: 'error'
            });
        });
    },

    computed: {
        currentMonthAndYear() {
            return moment().format('MM.YYYY');
        },

        maintenances() {
            if (this.listOfMaintenances === null) return [];

            let list = [];

            let filterActive = this.machineFilter !== 0;

            // console.log(this.listOfMaintenances);

            for (let maintenance of this.listOfMaintenances) {
                if (filterActive && maintenance[1] === this.machineFilter) {
                    return [
                        {
                            id: maintenance[0],
                            machine: this.$store.getters.machineGetById(maintenance[1]),
                            month: maintenance[2] < 10 ? '0' + maintenance[2] : maintenance[2],
                            year: maintenance[3]
                        }
                    ];
                } else {
                    list.push({
                        id: maintenance[0],
                        machine: this.$store.getters.machineGetById(maintenance[1]),
                        month: maintenance[2] < 10 ? '0' + maintenance[2] : maintenance[2],
                        year: maintenance[3]
                    });
                }
            }

            return list;
        }
    },

    methods: {
        // Store Actions
        ...mapActions({
            apiMachineLoadAll: 'machineLoadAll',
            apiMachineMarkMaintenanceAsDone: 'machineMarkMaintenanceAsDone',
            apiUserLoadAll: 'userLoadAll',
            apiLocationLoadAll: 'locationLoadAll'
        }),

        showCheckDescription(html) {
            this.$refs.checkDescriptionModalContent.innerHTML = html;

            this.$jquery(this.$refs.checkDescriptionModal).modal('show');
        },

        markMaintenanceAsDone(maintenanceId) {
            this.apiMachineMarkMaintenanceAsDone({ id: maintenanceId }).then(result => {

                this.$http.get('machine/upcoming-maintenances').then(result2 => {
                    this.listOfMaintenances = result2.data;

                    this.compLoaded = true;
                }).catch(error => {
                    // Fehler
                    this.$toast.open({
                        message: 'Fehler beim laden der Daten!',
                        type: 'error'
                    });
                });
            }).catch(error => {
                console.error(error);
            });
        },

        onPrintClick() {
            window.print();
            //window.open('/print/upcoming-maintenances', '_blank');
        }
    }
};
</script>

<style lang="scss" scoped>
@media print {
    @page { size: landscape !important; }

    [print-hide] {
        display: none;
    }

    [print-show] {
        display: block !important;
    }

    [print-width-one]   { width: 100px; }
    [print-width-two]   { width: 130px; }
    [print-width-three] { width: 85px;  }
    [print-width-four]  { width: 130px; }
    [print-width-five]  { width: 60px; }
    [print-width-six]  { width: 130px; }
}

[print-show] {
    display: none;
}

.modal .modal-header.bg-primary {
    & > .modal-title, & > button.close {
        color: white;
    }
}
</style>
