<template>
    <div class="comp-root" :class="{ 'comp-is-loading': !this.compLoaded }">
        <div class="blockquote">
            <h1 class="mb-0">Standorte</h1>
            <footer class="blockquote-footer">Eine Liste aller vorhandenen Standorte</footer>
        </div>

        <table class="table table-hover table-striped">
            <thead class="thead-dark">
                <tr>
                    <th title="ID">#</th>
                    <th>Name</th>
                    <th>Erstellt am</th>
                    <th>Letzte Aktualisierung am</th>
                    <th>Aktion</th>
                </tr>
            </thead>
            <tbody v-show="this.$store.state.locations.length === 0">
                <tr>
                    <td colspan="6">
                        Es wurden keine Kalibriervorschriften gefunden!
                    </td>
                </tr>
            </tbody>
            <tbody>
                <tr v-for="location in this.$store.state.locations">
                    <td>
                        #{{ location.id }}
                    </td>
                    <td>
                        <b>{{ location.name }}</b>
                    </td>
                    <td>
                        {{ location.created_at | moment("DD.MM.YYYY - HH:mm") }}
                    </td>
                    <td>
                        {{ location.updated_at | moment("DD.MM.YYYY - HH:mm") }}
                    </td>
                    <td>
                        <router-link
                            :to="{ name: 'location_edit', params: { id: location.id } }"
                            class="btn btn-sm btn-primary"
                        >Bearbeiten</router-link>

                        <router-link
                            :to="{ name: 'location_delete', params: { id: location.id } }"
                            class="btn btn-sm btn-danger"
                        >L&ouml;schen</router-link>
                    </td>
                </tr>
            </tbody>
        </table>

        <router-link :to="{ name: 'location_create' }" class="btn btn-success">
            <i class="fas fa-plus"></i> Standort anlegen
        </router-link>
    </div>
</template>

<script lang="ts">
import { mapActions } from 'vuex';

export default {
    name: 'location-list',

    data() {
        return {
            // Ist die Komponente komplett geladen und bereit?
            compLoaded: false
        };
    },

    created() {
        // Liste aller Standorte laden
        this.apiLocationLoadAll(true).then(success => {
            this.compLoaded = true;

        }).catch(reject => {
            // Fehler
            this.$toast.open({
                message: 'Fehler beim laden der Daten!',
                type: 'error'
            });
        });
    },

    methods: {
        // Store Actions
        ...mapActions({
            apiLocationLoadAll: 'locationLoadAll'
        })
    }
};
</script>

<style lang="scss" scoped>
</style>
