<template>
    <h1>Diese Funktion ist noch nicht implementiert!</h1>
</template>

<script lang="ts">
export default {
    name: 'dummy'
};
</script>

<style lang="scss" scoped>
h1 {
    color: red;
    border: 1px solid red;

    width: fit-content;
}
</style>
