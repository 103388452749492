<template>
    <div class="comp-root" :class="{ 'comp-is-loading': !this.compLoaded }">
        <div class="blockquote">
            <h1 class="mb-0">Kalibriervorschriften</h1>
            <footer class="blockquote-footer">Eine Liste aller vorhandenen Kalibriervorschriften</footer>
        </div>

        <table class="table table-hover table-striped">
            <thead class="thead-dark">
                <tr>
                    <th title="ID">#</th>
                    <th>Name</th>
                    <th>Beschreibung</th>
                    <th>Erstellt am</th>
                    <th>Letzte Aktualisierung am</th>
                    <th>Aktion</th>
                </tr>
            </thead>
            <tbody v-show="this.$store.state.calibrationRules.length === 0">
                <tr>
                    <td colspan="6">
                        Es wurden keine Kalibriervorschriften gefunden!
                    </td>
                </tr>
            </tbody>
            <tbody>
                <tr v-for="rule in this.$store.state.calibrationRules">
                    <td>
                        #{{ rule.id }}
                    </td>
                    <td>
                        <b>{{ rule.name }}</b>
                    </td>
                    <td>
                        <button
                            v-if="rule.description !== null"
                            class="btn btn-sm btn-primary"
                            title="Beschreibung ansehen"
                            @click="showDescription(rule.description)"
                        >
                             <i class="fas fa-info-circle" /> Ansehen
                        </button>
                        <span v-if="rule.description === null">-</span>
                    </td>
                    <td>
                        {{ rule.created_at | moment("DD.MM.YYYY - HH:mm") }}
                    </td>
                    <td>
                        {{ rule.updated_at | moment("DD.MM.YYYY - HH:mm") }}
                    </td>
                    <td>
                        <router-link
                            :to="{ name: 'calibrationrule_edit', params: { id: rule.id } }"
                            class="btn btn-sm btn-primary"
                        >Bearbeiten</router-link>

                        <router-link
                            :to="{ name: 'calibrationrule_delete', params: { id: rule.id } }"
                            class="btn btn-sm btn-danger"
                        >L&ouml;schen</router-link>
                    </td>
                </tr>
            </tbody>
        </table>

        <router-link :to="{ name: 'calibrationrule_create' }" class="btn btn-success">
            <i class="fas fa-plus"></i> Kalibriervorschrift anlegen
        </router-link>

        <!-- Beschreibung -->
        <div class="modal" tabindex="-1" role="dialog" ref="descriptionModal">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-primary">
                        <h5 class="modal-title">Beschreibung</h5>
                        <button type="button" class="close" data-dismiss="modal" title="Schließen">
                            <span>&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" ref="descriptionModalContent">
                        Inhalt
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { mapActions } from 'vuex';

export default {
    name: 'calibrationrule-list',

    data() {
        return {
            // Ist die Komponente komplett geladen und bereit?
            compLoaded: false
        };
    },

    created() {
        // Liste aller Kalibriervorschriften laden
        this.apiCalibrationRuleLoadAll(true).then(result => {
            this.compLoaded = true;

        }).catch(error => {
            // Fehler
            this.$toast.open({
                message: 'Fehler beim laden der Daten!',
                type: 'error'
            });
        });
    },

    methods: {
        // Store Actions
        ...mapActions({
            apiCalibrationRuleLoadAll: 'calibrationRuleLoadAll'
        }),

        showDescription(html) {
            this.$refs.descriptionModalContent.innerHTML = html;

            this.$jquery(this.$refs.descriptionModal).modal('show');
        }
    }
};
</script>

<style lang="scss" scoped>
.modal .modal-header.bg-primary {
    & > .modal-title, & > button.close {
        color: white;
    }
}
</style>
