<template>
    <form action="#" class="comp-root">
        <div class="blockquote">
            <h1 class="mb-0">Wartungsfristen</h1>
            <footer class="blockquote-footer">Wartungsfrist anlegen</footer>
        </div>

        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                Name*
                            </span>
                        </div>

                        <input
                            ref='name'
                            type="text"
                            :id="this._uid + 'name'"
                            class="form-control"
                            autocomplete="off"
                            v-model="name"
                        />
                    </div>
                </div>

                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                Frist*
                            </span>
                        </div>

                        <input
                            ref='interval'
                            type="text"
                            :id="this._uid + 'interval'"
                            class="form-control"
                            autocomplete="off"
                            v-model="interval"
                        />

                        <div class="input-group-append">
                            <span class="input-group-text">
                                <a target="_blank" title="Im Crontab-Format" href="https://crontab.guru/">
                                    <i class="fas fa-info-circle" />
                                </a>
                            </span>
                        </div>
                    </div>
                </div>

                <button type="button" class="btn btn-primary" @click="onSaveClick">
                    <i class="fas fa-save"></i> Anlegen
                </button>

                <button type="button" class="btn btn-secondary" @click="onCancelClick">
                    <i class="fas fa-undo"></i> Abbrechen
                </button>
            </div>
        </div>
    </form>
</template>

<script lang="ts">
import { mapActions } from 'vuex';

export default {
    name: 'checkinterval-create',

    data() {
        return {
            // Name
            name: '',

            // Frist
            interval: ''
        };
    },

    methods: {
        // Store Actions
        ...mapActions({
            apiCheckIntervalCreate: 'checkIntervalCreate'
        }),

        // "Speichern" Button
        onSaveClick() {
            // Werte holen
            let valueName = this.name;
            let valueInterval = this.interval;

            // Pflicht-Felder pruefen
            if (valueName === '' || valueInterval === '') {
                this.$toast.open({
                    message: 'Bitte alle Pflicht-Felder ausfüllen',
                    type: 'warning'
                });

                return;
            }

            // Payload erstellen
            let payload = {
                name: valueName,
                interval: valueInterval
            };

            // Anfrage ans Backend schicken
            this.apiCheckIntervalCreate({ data: payload }).then(result => {
                // Erfolgreich
                this.$toast.open({
                    message: 'Wartungsfrist erfolgreich angelegt',
                    type: 'success'
                });

                this.$router.back();

            }).catch(error => {
                // Fehler
                this.$toast.open({
                    message: 'Fehler beim anlegen der Wartungsfrist!',
                    type: 'error'
                });

            });
        },

        // "Abbrechen" Button
        onCancelClick() {
            this.$router.back();
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
