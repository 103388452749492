// Components
import ComponentDummy from './components/dummy/dummy';

import ComponentDevTest from './components/dev/test';

import ComponentStartView from './components/start/view.vue';
import ComponentAuthLogin from './components/auth/login.vue';
import ComponentAuthLogout from './components/auth/logout.vue';

import ComponentMachineList from './components/machine/list';
import ComponentMachineCreate from './components/machine/create';
import ComponentMachineEdit from './components/machine/edit';
import ComponentMachineDelete from './components/machine/delete';
import ComponentMachineView from './components/machine/view';

import ComponentLocationList from './components/location/list';
import ComponentLocationCreate from './components/location/create';
import ComponentLocationEdit from './components/location/edit';
import ComponentLocationDelete from './components/location/delete';

import ComponentCheckIntervalList from './components/checkinterval/list';
import ComponentCheckIntervalCreate from './components/checkinterval/create';
import ComponentCheckIntervalEdit from './components/checkinterval/edit';
import ComponentCheckIntervalDelete from './components/checkinterval/delete';

import ComponentCheckUtilList from './components/checkutil/list';
import ComponentCheckUtilCreate from './components/checkutil/create';
import ComponentCheckUtilEdit from './components/checkutil/edit';
import ComponentCheckUtilDelete from './components/checkutil/delete';

import ComponentCalibrationRuleList from './components/calibrationrule/list';
import ComponentCalibrationRuleCreate from './components/calibrationrule/create';
import ComponentCalibrationRuleEdit from './components/calibrationrule/edit';
import ComponentCalibrationRuleDelete from './components/calibrationrule/delete';

import ComponentManufacturerList from './components/manufacturer/list';
import ComponentManufacturerCreate from './components/manufacturer/create';
import ComponentManufacturerEdit from './components/manufacturer/edit';
import ComponentManufacturerDelete from './components/manufacturer/delete';

export default [
    {
        path: '/',
        redirect: '/start',
        meta: { need_login: false }
    },

    // Startseite
    {
        path: '/start',
        name: 'start',
        component: ComponentStartView,
        meta: { need_login: true }
    },

    // Dev Testseite
    {
        path: '/dev',
        name: 'dev_test',
        component: ComponentDevTest,
        meta: { need_login: true }
    },

    // Auth
    {
        path: '/auth/login?previous=:previous',
        name: 'auth_login',
        component: ComponentAuthLogin,
        meta: { need_login: false }
    },
    {
        path: '/auth/logout',
        name: 'auth_logout',
        component: ComponentAuthLogout,
        meta: { need_login: true }
    },

    // Maschinen
    {
        path: '/machine/list',
        name: 'machine_list',
        component: ComponentMachineList,
        meta: { need_login: true }
    },
    {
        path: '/machine/create',
        name: 'machine_create',
        component: ComponentMachineCreate,
        meta: { need_login: true }
    },
    {
        path: '/machine/edit/:id',
        name: 'machine_edit',
        component: ComponentMachineEdit,
        meta: { need_login: true }
    },
    {
        path: '/machine/delete/:id',
        name: 'machine_delete',
        component: ComponentMachineDelete,
        meta: { need_login: true }
    },
    {
        path: '/machine/view/:id',
        name: 'machine_view',
        component: ComponentMachineView,
        meta: { need_login: true }
    },

    // Standorte
    {
        path: '/location/list',
        name: 'location_list',
        component: ComponentLocationList,
        meta: { need_login: true }
    },
    {
        path: '/location/create',
        name: 'location_create',
        component: ComponentLocationCreate,
        meta: { need_login: true }
    },
    {
        path: '/location/edit/:id',
        name: 'location_edit',
        component: ComponentLocationEdit,
        meta: { need_login: true }
    },
    {
        path: '/location/delete/:id',
        name: 'location_delete',
        component: ComponentLocationDelete,
        meta: { need_login: true }
    },

    // Wartungsfristen
    {
        path: '/check-interval/list',
        name: 'checkinterval_list',
        component: ComponentCheckIntervalList,
        meta: { need_login: true }
    },
    {
        path: '/check-interval/create',
        name: 'checkinterval_create',
        component: ComponentCheckIntervalCreate,
        meta: { need_login: true }
    },
    {
        path: '/check-interval/edit/:id',
        name: 'checkinterval_edit',
        component: ComponentCheckIntervalEdit,
        meta: { need_login: true }
    },
    {
        path: '/check-interval/delete/:id',
        name: 'checkinterval_delete',
        component: ComponentCheckIntervalDelete,
        meta: { need_login: true }
    },

    // Pruefhilfsmittel
    {
        path: '/check-util/list',
        name: 'checkutil_list',
        component: ComponentCheckUtilList,
        meta: { need_login: true }
    },
    {
        path: '/check-util/create',
        name: 'checkutil_create',
        component: ComponentCheckUtilCreate,
        meta: { need_login: true }
    },
    {
        path: '/check-util/edit/:id',
        name: 'checkutil_edit',
        component: ComponentCheckUtilEdit,
        meta: { need_login: true }
    },
    {
        path: '/check-util/delete/:id',
        name: 'checkutil_delete',
        component: ComponentCheckUtilDelete,
        meta: { need_login: true }
    },

    // Kalibriervorschriften
    {
        path: '/calibration-rule/list',
        name: 'calibrationrule_list',
        component: ComponentCalibrationRuleList,
        meta: { need_login: true }
    },
    {
        path: '/calibration-rule/create',
        name: 'calibrationrule_create',
        component: ComponentCalibrationRuleCreate,
        meta: { need_login: true }
    },
    {
        path: '/calibration-rule/edit/:id',
        name: 'calibrationrule_edit',
        component: ComponentCalibrationRuleEdit,
        meta: { need_login: true }
    },
    {
        path: '/calibration-rule/delete/:id',
        name: 'calibrationrule_delete',
        component: ComponentCalibrationRuleDelete,
        meta: { need_login: true }
    },

    // Hersteller
    {
        path: '/manufacturer/list',
        name: 'manufacturer_list',
        component: ComponentManufacturerList,
        meta: { need_login: true }
    },
    {
        path: '/manufacturer/create',
        name: 'manufacturer_create',
        component: ComponentManufacturerCreate,
        meta: { need_login: true }
    },
    {
        path: '/manufacturer/edit/:id',
        name: 'manufacturer_edit',
        component: ComponentManufacturerEdit,
        meta: { need_login: true }
    },
    {
        path: '/manufacturer/delete/:id',
        name: 'manufacturer_delete',
        component: ComponentManufacturerDelete,
        meta: { need_login: true }
    },

    // Einstellungen
    {
        path: '/settings',
        name: 'settings',
        component: ComponentDummy,
        meta: { need_login: true }
    },
    {
        path: '/settings/user',
        name: 'settings_user',
        component: ComponentDummy,
        meta: { need_login: true }
    },
    {
        path: '/settings/developer',
        name: 'settings_dev',
        component: ComponentDummy,
        meta: { need_login: true, is_dev: true }
    },
];
