<template>
    <form action="#" class="comp-root">
        <div class="blockquote">
            <h1 class="mb-0">Standorte</h1>
            <footer class="blockquote-footer">Standort anlegen</footer>
        </div>

        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                Name*
                            </span>
                        </div>

                        <input
                            ref='name'
                            type="text"
                            :id="this._uid + 'name'"
                            class="form-control"
                            autocomplete="off"
                            v-model="name"
                        />
                    </div>
                </div>

                <button type="button" class="btn btn-primary" @click="onSaveClick">
                    <i class="fas fa-save"></i> Anlegen
                </button>

                <button type="button" class="btn btn-secondary" @click="onCancelClick">
                    <i class="fas fa-undo"></i> Abbrechen
                </button>
            </div>
        </div>
    </form>
</template>

<script lang="ts">
import { mapActions } from 'vuex';

export default {
    name: 'location-create',

    data() {
        return {
            // Name
            name: ''
        };
    },

    methods: {
        // Store Actions
        ...mapActions({
            apiLocationCreate: 'locationCreate'
        }),

        // "Speichern" Button
        onSaveClick() {
            // Werte holen
            let valueName = this.name;

            // Pflicht-Felder pruefen
            if (valueName === '') {
                this.$toast.open({
                    message: 'Bitte alle Pflicht-Felder ausfüllen',
                    type: 'warning'
                });

                return;
            }

            // Payload erstellen
            let payload = {
                name: valueName
            };

            // Anfrage ans Backend schicken
            this.apiLocationCreate({ data: payload }).then(result => {
                // Erfolgreich
                this.$toast.open({
                    message: 'Standort erfolgreich angelegt',
                    type: 'success'
                });

                this.$router.back();

            }).catch(reject => {
                // Fehler
                this.$toast.open({
                    message: 'Fehler beim anlegen des Standorts!',
                    type: 'error'
                });
            });
        },

        // "Abbrechen" Button
        onCancelClick() {
            this.$router.back();
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
