<template>
    <div class="comp-root" :class="{ 'comp-is-loading': !this.compLoaded }">
        <div class="blockquote">
            <h1 class="mb-0">Maschinen</h1>
            <footer class="blockquote-footer">Maschine "{{ machine.name }}"</footer>
        </div>

        <button class="btn btn-sm btn-primary mb-3" @click="onPrintClick" print-hide>
            <i class="fas fa-print"></i> Drucken
        </button>

        <table class="table">
            <thead class="thead-dark">
                <tr>
                    <th>Name</th>
                    <th>Hersteller</th>
                    <th>Standort</th>
                    <th title="Registrierungs-Nummer">Reg.-Nr.</th>
                    <th title="Geräte-Nummer">Ger&auml;te-Nr.</th>
                    <th title="Alter der Maschine in Jahren">Alter</th>
                    <th>Verantwortlicher</th>
                    <th>Wartungsfrist</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ machine.name }}</td>
                    <td>{{ manufacturer.name }}</td>
                    <td>{{ location.name }}</td>
                    <td>{{ machine.registration_number }}</td>
                    <td>{{ machine.device_number || 'N/A' }}</td>
                    <td>{{ age }} Jahr(e)</td>
                    <td>{{ maintainer.name }}</td>
                    <td>{{ maintenanceInterval.name }}</td>
                </tr>
            </tbody>
        </table>

        <hr />

        <div class="row">
            <div class="col-3">
                <b>Hilfsmittel:</b><br />
                {{ checkUtils }}
            </div>
            <div class="col-3">
                <b>Messgr&ouml;&szlig;e:</b><br />
                {{ machine.measurement_variable || 'N/A' }}<br /><br />

                <b>Messbereich:</b><br />
                {{ machine.measurement_range || 'N/A' }}<br /><br />

                <b>Toleranz:</b><br />
                {{ machine.tolerance || 'N/A' }}<br /><br />
            </div>
            <div class="col-6">
                <b>Kalibrier-Vorschrift:</b><br />
                {{ calibrationRule.name }}<br /><br />
                <span v-html="calibrationRule.description"></span>
            </div>
        </div>

        <hr />

        <p><b>Wartungsarbeiten:</b></p>
        <span v-html="machine.check_description"></span>

        <!--Hilfsmittel, Kalibrier-Vorschrift, Meßgrößen, Meßbereich, Toleranz ... -->

        <hr />

        <button class="btn btn-success mb-3" @click="onTaskAddClick" print-hide>
            <i class="fas fa-plus"></i> Vermerk hinzuf&uuml;gen
        </button>

        <table class="table table-sm">
            <thead class="thead-dark">
                <tr>
                    <th style="width: 10%;">Datum</th>
                    <th style="width: 60%;">Pr&uuml;fvermerk</th>
                    <th style="width: 15%;">Pr&uuml;fer</th>
                    <th style="width: 5%;">Status</th>
                    <th style="width: 10%;" print-hide>Aktion</th>
                </tr>
            </thead>
            <tbody v-show="this.tasks.length === 0">
                <tr>
                    <td colspan="5">
                        Es wurden keine Pr&uuml;fvermerke gefunden!
                    </td>
                </tr>
            </tbody>
            <tbody>
                <tr v-for="task in this.tasks" :class="{ 'table-success': task.done }">
                    <td>{{ task.date }}</td>
                    <td>{{ task.note }}</td>
                    <td><b>{{ user_names[task.user] }}</b></td>
                    <td>
                        <i class="far fa-circle" v-if="!task.done" title="Offen"></i>
                        <i class="far fa-check-circle" v-if="task.done" title="Erledigt"></i>
                    </td>
                    <td print-hide>
                        <i class="fas fa-cursor fa-fw fa-pencil-alt" title="Bearbeiten" @click="onTaskEditClick(task.id)"></i>
                        <i class="fas fa-cursor fa-fw fa-trash-alt" title="Löschen" @click="onTaskDeleteClick(task.id)"></i>
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- Task hinzufuegen Modal -->
        <div class="modal" tabindex="-1" role="dialog" ref="taskAddModal">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-success">
                        <h5 class="modal-title">Vermerk hinzuf&uuml;gen</h5>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label :for="this._uid + 'taskadd_date'">Datum</label>
                            <input type="text" class="form-control" :id="this._uid + 'taskadd_date'" v-model="taskToAddData.date" />
                        </div>
                        <div class="form-group">
                            <label :for="this._uid + 'taskadd_note'">Vermerk</label>
                            <textarea class="form-control" :id="this._uid + 'taskadd_note'" rows="8" v-model="taskToAddData.note"></textarea>
                        </div>
                        <div class="form-group cmp-checkbox">
                            <input type="checkbox" :id="this._uid + 'taskadd_done'" v-model="taskToAddData.done" />
                            <label :for="this._uid + 'taskadd_done'">Erledigt?</label>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success" @click="onTaskAddSaveClick">Hinzuf&uuml;gen</button>
                        <button type="button" class="btn btn-primary" @click="onTaskAddCancelClick">Abbrechen</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Task bearbeiten Modal -->
        <div class="modal" tabindex="-1" role="dialog" ref="taskEditModal">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-primary">
                        <h5 class="modal-title">Vermerk bearbeiten</h5>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label :for="this._uid + 'taskedit_date'">Datum</label>
                            <input type="text" class="form-control" :id="this._uid + 'taskedit_date'" v-model="taskToEditData.date" />
                        </div>
                        <div class="form-group">
                            <label :for="this._uid + 'taskedit_note'">Vermerk</label>
                            <textarea class="form-control" :id="this._uid + 'taskedit_note'" rows="8" v-model="taskToEditData.note"></textarea>
                        </div>
                        <div class="form-group cmp-checkbox">
                            <input type="checkbox" :id="this._uid + 'taskedit_done'" v-model="taskToEditData.done" />
                            <label :for="this._uid + 'taskedit_done'">Erledigt?</label>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success" @click="onTaskEditSaveClick">Speichern</button>
                        <button type="button" class="btn btn-primary" @click="onTaskEditCancelClick">Abbrechen</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Task loeschen Modal -->
        <div class="modal" tabindex="-1" role="dialog" ref="taskDeleteModal">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-danger">
                        <h5 class="modal-title">Vermerk l&ouml;schen</h5>
                    </div>
                    <div class="modal-body">
                        Soll der Vermerk wirklich gel&ouml;scht werden?
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" @click="onTaskDeleteConfirmClick">Ja</button>
                        <button type="button" class="btn btn-primary" @click="onTaskDeleteCancelClick">Nein / Abbrechen</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import moment from 'moment';
import { mapActions } from 'vuex';

export default {
    name: 'machine-view',

    data() {
        return {
            // Ist die Komponente komplett geladen und bereit?
            compLoaded: false,

            // Datenbank-ID
            machineId: 0,

            // Maschine
            machine: { name: '', check_description: '<h1>It works</h1>' },

            // Tasks
            tasks: [],

            // User ID <-> Name Cache
            user_names: {},

            // Task hinzufuegen
            taskToAddData: { date: moment().format('DD.MM.YYYY'), note: '', done: false },

            // Task ID, die zum bearbeiten ausgewaehlt wurde
            taskIdToEdit: 0,
            taskToEditData: { date: '', note: '', done: false },

            // Task ID, die zum loeschen ausgewaehlt wurde
            taskIdToDelete: 0
        };
    },

    created() {
        // ID aus Route auslesen
        this.machineId = parseInt(this.$route.params.id);

        // Daten im Cache erneuern
        Promise.all([
            this.apiMachineLoadAll(true),
            this.apiManufacturerLoadAll(true),
            this.apiLocationLoadAll(true),
            this.apiUserLoadAll(true),
            this.apiCheckIntervalLoadAll(true),
            this.apiCheckUtilLoadAll(true),
            this.apiCalibrationRuleLoadAll(true)
        ]).then(() => {
            // Daten holen
            this.machine = this.$store.getters.machineGetById(this.machineId);

            // Konnten die Daten erfolgreich geladen werden?
            if (this.machine !== undefined) {
                // User-ID <-> Name cachen. Spart den andauernden Aufruf des Getters (Overhead!)
                for (let user of this.$store.state.users) {
                    this.user_names[user.id] = user.name;
                }

                // Tasks laden
                this.apiMachineTaskGetByMachineId({ id: this.machineId }).then(result => {
                    this.tasks = result.data;

                    this.compLoaded = true;
                }).catch(error => {
                    // Fehler
                    this.$toast.open({
                        message: 'Fehler beim laden der Daten!',
                        type: 'error'
                    });
                });
            } else {
                // Fehler
                this.$toast.open({
                    message: 'Fehler beim laden der Daten!',
                    type: 'error'
                });

                this.$router.back();
            }
        }).catch(() => {
            // Fehler
            this.$toast.open({
                message: 'Fehler beim laden der Daten!',
                type: 'error'
            });
        });
    },

    computed: {
        manufacturer() {
            return this.$store.getters.manufacturerGetById(this.machine.manufacturer);
        },

        location() {
            return this.$store.getters.locationGetById(this.machine.location);
        },

        age() {
            let result;

            if (this.machine.acquisition_year === null) {
                return '-';
            }

            return '' + (new Date()).getFullYear() - parseInt(this.machine.acquisition_year);
        },

        maintainer() {
            return this.$store.getters.userGetById(this.machine.maintainer);
        },

        maintenanceInterval() {
            return this.$store.getters.checkIntervalGetById(this.machine.maintenance_interval);
        },

        checkUtils() {
            let result = [];

            for (let singleUtil of this.machine.check_utils) {
                result.push(singleUtil.name);
            }

            return result.join(', ');
        },

        calibrationRule() {
            return this.$store.getters.calibrationRuleGetById(this.machine.calibration_rule);
        }
    },

    methods: {
        // Store Actions
        ...mapActions({
            apiMachineLoadAll: 'machineLoadAll',
            apiUserLoadAll: 'userLoadAll',
            apiManufacturerLoadAll: 'manufacturerLoadAll',
            apiLocationLoadAll: 'locationLoadAll',
            apiCheckIntervalLoadAll: 'checkIntervalLoadAll',
            apiCheckUtilLoadAll: 'checkUtilLoadAll',
            apiCalibrationRuleLoadAll: 'calibrationRuleLoadAll',

            apiMachineTaskGetByMachineId: 'machineTaskGetByMachineId',
            apiMachineTaskCreate: 'machineTaskCreate',
            apiMachineTaskEdit: 'machineTaskEdit',
            apiMachineTaskDelete: 'machineTaskDelete',
        }),

        onPrintClick() {
            window.print();
            //window.open('/print/machine-card/' + this.machineId, '_blank');
        },

        onTaskAddClick() {
            this.$jquery(this.$refs.taskAddModal).modal('show');
        },

        onTaskAddSaveClick() {
            let valueDate = this.taskToAddData.date;
            let valueNote = this.taskToAddData.note;
            let valueDone = this.taskToAddData.done;

            // Pflicht-Felder pruefen
            if (valueDate === '' || valueNote === '') {
                this.$toast.open({
                    message: 'Bitte alle Pflicht-Felder ausfüllen',
                    type: 'warning'
                });

                return;
            }

            // Payload erstellen
            let payload = {
                user: this.$store.state.app.user.id,
                machine: this.machineId,
                date: valueDate,
                note: valueNote,
                done: valueDone
            };

            // Anfrage ans Backend schicken
            this.apiMachineTaskCreate({ data: payload }).then(result => {
                // Tasks laden
                this.apiMachineTaskGetByMachineId({ id: this.machineId }).then(result2 => {
                    this.tasks = result2.data;

                    this.$jquery(this.$refs.taskAddModal).modal('hide');

                    this.taskToAddData.date = moment().format('DD.MM.YYYY');
                    this.taskToAddData.note = '';
                    this.taskToAddData.done = false;

                    // Erfolgreich
                    this.$toast.open({
                        message: 'Prüfvermerk erfolgreich angelegt',
                        type: 'success'
                    });
                }).catch(error2 => {
                    // Fehler
                    this.$toast.open({
                        message: 'Fehler beim laden der Daten!',
                        type: 'error'
                    });
                });

            }).catch(error => {
                // Fehler
                this.$toast.open({
                    message: 'Fehler beim anlegen des Prüfvermerks!',
                    type: 'error'
                });

            });
        },

        onTaskAddCancelClick() {
            this.taskToAddData.date = moment().format('DD.MM.YYYY');
            this.taskToAddData.note = '';
            this.taskToAddData.done = false;

            this.$jquery(this.$refs.taskAddModal).modal('hide');
        },

        onTaskEditClick(taskId) {
            this.taskIdToEdit = taskId;

            let taskInfo = this._utilTaskGetById(taskId);

            this.taskToEditData.date = taskInfo.date;
            this.taskToEditData.note = taskInfo.note;
            this.taskToEditData.done = taskInfo.done;

            this.$jquery(this.$refs.taskEditModal).modal('show');
        },

        onTaskEditSaveClick() {
            let valueDate = this.taskToEditData.date;
            let valueNote = this.taskToEditData.note;
            let valueDone = this.taskToEditData.done;

            // Pflicht-Felder pruefen
            if (valueDate === '' || valueNote === '') {
                this.$toast.open({
                    message: 'Bitte alle Pflicht-Felder ausfüllen',
                    type: 'warning'
                });

                return;
            }

            // Payload erstellen
            let payload = {
                date: valueDate,
                note: valueNote,
                done: valueDone
            };

            // Anfrage ans Backend schicken
            this.apiMachineTaskEdit({ id: this.taskIdToEdit, data: payload }).then(result => {
                // Tasks laden
                this.apiMachineTaskGetByMachineId({ id: this.machineId }).then(result2 => {
                    this.tasks = result2.data;

                    this.$jquery(this.$refs.taskEditModal).modal('hide');

                    // Erfolgreich
                    this.$toast.open({
                        message: 'Änderungen erfolgreich gespeichert',
                        type: 'success'
                    });
                }).catch(error2 => {
                    // Fehler
                    this.$toast.open({
                        message: 'Fehler beim laden der Daten!',
                        type: 'error'
                    });
                });

            }).catch(error => {
                // Fehler
                this.$toast.open({
                    message: 'Fehler beim Speichern der Änderungen!',
                    type: 'error'
                });

            });
        },

        onTaskEditCancelClick() {
            this.taskIdToEdit = 0;

            this.taskToEditData.date = '';
            this.taskToEditData.note = '';
            this.taskToEditData.done = false;

            this.$jquery(this.$refs.taskEditModal).modal('hide');
        },

        onTaskDeleteClick(taskId) {
            this.taskIdToDelete = taskId;

            this.$jquery(this.$refs.taskDeleteModal).modal('show');
        },

        onTaskDeleteConfirmClick() {
            this.apiMachineTaskDelete({ id: this.taskIdToDelete }).then(result => {
                // Tasks laden
                this.apiMachineTaskGetByMachineId({ id: this.machineId }).then(result2 => {
                    this.tasks = result2.data;

                    this.$jquery(this.$refs.taskDeleteModal).modal('hide');

                    this.$toast.open({
                        message: 'Prüfvermerk erfolgreich gelöscht',
                        type: 'success'
                    });
                }).catch(error2 => {
                    // Fehler
                    this.$toast.open({
                        message: 'Fehler beim laden der Daten!',
                        type: 'error'
                    });
                });

            }).catch(error => {
                // Fehler
                this.$toast.open({
                    message: 'Fehler beim löschen des Prüfvermerks!',
                    type: 'error'
                });
            });
        },

        onTaskDeleteCancelClick() {
            this.taskIdToDelete = 0;
            this.$jquery(this.$refs.taskDeleteModal).modal('hide');
        },

        _utilTaskGetById(taskId) {
            return this.tasks.find(task => task.id === taskId);
        }
    }
};
</script>

<style lang="scss" scoped>
@media print {
    @page { size: portrait !important; }
    [print-hide] {
        display: none;
    }
}

.fa-cursor {
    cursor: pointer;
}

.modal .modal-header.bg-primary, .modal .modal-header.bg-danger, .modal .modal-header.bg-success {
    & > .modal-title, & > button.close {
        color: white;
    }
}

.cmp-checkbox label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px
}

.cmp-checkbox input[type="checkbox"] {
    opacity: 0;
    z-index: 1;
}

.cmp-checkbox label:before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    margin-left: -20px;
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color: #fff;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    margin-top: 4px;
}

.cmp-checkbox label:after {
    margin-top: 4px;
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    top: 0;
    margin-left: -20px;
    padding-left: 3px;
    padding-top: 1px;
    font-size: 11px;
    color: #555;
}

.cmp-checkbox input[type="checkbox"]:checked + label:before {
    background-color: #007BFF;
    border-color: #007BFF;
}

.cmp-checkbox input[type="checkbox"]:checked + label:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\F00C";
    color: white;
}
</style>
