<template>
    <div class="comp-root" :class="{ 'comp-is-loading': !this.compLoaded }">
        <div class="row">
            <div class="col-6">
                <div class="blockquote">
                    <h1 class="mb-0">Maschinen</h1>
                    <footer class="blockquote-footer">Eine Liste aller vorhandenen Maschinen</footer>
                </div>
            </div>
            <div class="col-6 text-right">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">Suche:</span>
                    </div>
                    <input type="text" class="form-control" v-model='searchInput' />
                </div>
            </div>
        </div>

        <table class="table table-hover table-striped">
            <thead class="thead-dark">
                <tr>
                    <th title="ID">#</th>
                    <th>Name</th>
                    <th>Standort</th>
                    <th>Registrier-Nr.</th>
                    <th>Erstellt am</th>
                    <th>Letzte Aktualisierung am</th>
                    <th>Aktion</th>
                </tr>
            </thead>
            <tbody v-show="machineList.length === 0">
                <tr>
                    <td colspan="7">
                        Es wurden keine Maschinen gefunden!
                    </td>
                </tr>
            </tbody>
            <tbody>
                <tr v-for="machine in machineList">
                    <td>
                        #{{ machine.id }}
                    </td>
                    <td>
                        <router-link :to="{ name: 'machine_view', params: { id: machine.id } }" title="Maschinen-Karte öffnen">
                            <b>{{ machine.name }}</b> <i class="fas fa-fw fa-external-link-alt"></i>
                        </router-link>
                    </td>
                    <td>
                        {{ $store.getters.locationGetById(machine.location).name }}
                    </td>
                    <td>
                        {{ machine.registration_number }}
                    </td>
                    <td>
                        {{ machine.created_at | moment("DD.MM.YYYY - HH:mm") }}
                    </td>
                    <td>
                        {{ machine.updated_at | moment("DD.MM.YYYY - HH:mm") }}
                    </td>
                    <td>
                        <div class="btn-group">
                            <router-link
                                :to="{ name: 'machine_edit', params: { id: machine.id } }"
                                class="btn btn-sm btn-primary"
                            >Bearbeiten</router-link>

                            <router-link
                                :to="{ name: 'machine_delete', params: { id: machine.id } }"
                                class="btn btn-sm btn-danger"
                            >L&ouml;schen</router-link>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <router-link :to="{ name: 'machine_create' }" class="btn btn-success">
            <i class="fas fa-plus"></i> Maschine anlegen
        </router-link>
    </div>
</template>

<script lang="ts">
import { mapActions } from 'vuex';

export default {
    name: 'machine-list',

    data() {
        return {
            // Ist die Komponente komplett geladen und bereit?
            compLoaded: false,

            // Suchbegriff
            searchInput: ''
        };
    },

    created() {
        // Daten im Cache erneuern
        Promise.all([
            this.apiLocationLoadAll(true),
            this.apiMachineLoadAll(true)
        ]).then(() => {
            this.compLoaded = true;

        }).catch(() => {
            // Fehler
            this.$toast.open({
                message: 'Fehler beim laden der Daten!',
                type: 'error'
            });
        });
    },

    methods: {
        // Store Actions
        ...mapActions({
            apiMachineLoadAll: 'machineLoadAll',
            apiLocationLoadAll: 'locationLoadAll',
            apiUserLoadAll: 'userLoadAll',
        })
    },

    computed: {
        machineList() {
            let machines = [];

            let searchTerm = this.searchInput.toLowerCase();

            for (let singleMachine of this.$store.state.machines) {
                let machineName = singleMachine.name;
                let machineDeviceNumber = singleMachine.device_number;
                let machineRegistrationNumber = singleMachine.registration_number;
                let machineCheckDescription = singleMachine.check_description;

                if (searchTerm === '') {
                    machines.push(singleMachine);
                } else {
                    if (
                        machineName.toLowerCase().includes(searchTerm)
                        || machineDeviceNumber.toLowerCase().includes(searchTerm)
                        || machineRegistrationNumber.toLowerCase().includes(searchTerm)
                        || machineCheckDescription.toLowerCase().includes(searchTerm)
                    ) {
                        machines.push(singleMachine);
                    }
                }
            }

            return machines;
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
