<template>
    <form action="#" class="comp-root" :class="{ 'comp-is-loading': !this.compLoaded }">
        <div class="blockquote">
            <h1 class="mb-0">Maschinen</h1>
            <footer class="blockquote-footer">Maschine "{{ machine.name }}" bearbeiten</footer>
        </div>

        <div class="row">
            <div class="col-6">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            Name/Bezeichnung*
                        </span>
                    </div>

                    <input
                        ref="name"
                        type="text"
                        :id="this._uid + 'name'"
                        class="form-control"
                        autocomplete="off"
                        v-model="machineEdit.name"
                    />
                </div>

                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            Ger&auml;te-Nummer
                        </span>
                    </div>

                    <input
                        ref="deviceNumber"
                        type="text"
                        :id="this._uid + 'device_number'"
                        class="form-control"
                        autocomplete="off"
                        v-model="machineEdit.device_number"
                    />
                </div>

                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            Standort*
                        </span>
                    </div>

                    <select
                        ref="location"
                        class="form-control"
                        :id="this._uid + 'location'"
                        v-model.number="machineEdit.location"
                    >
                        <option value="0">-</option>
                        <option v-for="option in this.$store.state.locations" :value="option.id">{{ option.name }}</option>
                    </select>
                </div>

                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            Messgr&ouml;&szlig;e(n)
                        </span>
                    </div>

                    <input
                        ref="measurementVariable"
                        type="text"
                        :id="this._uid + 'measurement_variable'"
                        class="form-control"
                        autocomplete="off"
                        v-model="machineEdit.measurement_variable"
                    />
                </div>

                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            Messbereich
                        </span>
                    </div>

                    <input
                        ref="measurementRange"
                        type="text"
                        :id="this._uid + 'measurement_range'"
                        class="form-control"
                        autocomplete="off"
                        v-model="machineEdit.measurement_range"
                    />
                </div>

                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            Wartungsfrist*
                        </span>
                    </div>

                    <select
                        ref="maintenanceInterval"
                        class="form-control"
                        :id="this._uid + 'maintenance_interval'"
                        v-model.number="machineEdit.maintenance_interval"
                    >
                        <option value="0">-</option>
                        <option v-for="option in this.$store.state.checkIntervals" :value="option.id">{{ option.name }}</option>
                    </select>
                </div>
            </div>

            <div class="col-6">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            Hersteller*
                        </span>
                    </div>

                    <select
                        ref="manufacturer"
                        class="form-control"
                        :id="this._uid + 'manufacturer'"
                        v-model.number="machineEdit.manufacturer"
                    >
                        <option value="0">-</option>
                        <option v-for="option in this.$store.state.manufacturer" :value="option.id">{{ option.name }}</option>
                    </select>
                </div>

                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            Registrier-Nr.*
                        </span>
                    </div>

                    <input
                        ref="registrationNumber"
                        type="text"
                        :id="this._uid + 'registration_number'"
                        class="form-control"
                        autocomplete="off"
                        v-model="machineEdit.registration_number"
                    />
                </div>

                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            Anschaffungsjahr
                        </span>
                    </div>

                    <input
                        ref="acquisitionYear"
                        type="number"
                        :id="this._uid + 'acquisition_year'"
                        class="form-control"
                        autocomplete="off"
                        min="1900"
                        max="2100"
                        v-model="machineEdit.acquisition_year"
                    />
                </div>

                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            Toleranz
                        </span>
                    </div>

                    <input
                        ref="tolerance"
                        type="text"
                        :id="this._uid + 'tolerance'"
                        class="form-control"
                        autocomplete="off"
                        v-model="machineEdit.tolerance"
                    />
                </div>

                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            Kalibriervorschrift*
                        </span>
                    </div>

                    <select
                        ref="calibrationRule"
                        class="form-control"
                        :id="this._uid + 'calibration_rule'"
                        v-model.number="machineEdit.calibration_rule"
                    >
                        <option value="0">-</option>
                        <option v-for="option in this.$store.state.calibrationRules" :value="option.id">{{ option.name }}</option>
                    </select>
                </div>

                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            Verantwortlicher*
                        </span>
                    </div>

                    <select
                        ref="maintainer"
                        class="form-control"
                        :id="this._uid + 'maintainer'"
                        v-model.number="machineEdit.maintainer"
                    >
                        <option value="0">-</option>
                        <option v-for="option in this.$store.state.users" :value="option.id">{{ option.name }}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" style="align-items: flex-start">
                            Wartungsarbeiten
                        </span>
                    </div>

                    <vue-mce
                        class="form-control tinymce-size-medium"
                        :config="this.$utils.tinyMceConfig"
                        v-model="machineEdit.check_description"
                        @init="onCheckDescriptionEditorInit"
                    />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            Pr&uuml;fhilfsmittel
                        </span>
                    </div>

                    <v-select
                        class="form-control"
                        v-model="machineEdit.check_utils"
                        :options="this.$store.state.checkUtils"
                        :multiple="true"
                        :getOptionLabel="checkUtilGetLabel"
                        :getOptionKey="checkUtilGetValue"
                        :reduce="checkUtilReduce"
                    ></v-select>
                </div>
            </div>
        </div>

        <button type="button" class="btn btn-primary" @click="onSaveClick">
            <i class="fas fa-save"></i> Speichern
        </button>

        <button type="button" class="btn btn-secondary" @click="onCancelClick">
            <i class="fas fa-undo"></i> Abbrechen
        </button>
    </form>
</template>

<script lang="ts">
import {mapActions} from "vuex";

export default {
    name: 'machine-edit',

    data() {
        return {
            // Ist die Komponente komplett geladen und bereit?
            compLoaded: false,

            // Datenbank-ID
            machineId: 0,

            // Originale Version
            machine: {},

            // Bearbeitete Version
            machineEdit: {}
        };
    },

    created() {
        // ID aus Route auslesen
        this.machineId = parseInt(this.$route.params.id);

        // Daten holen (falls noch nicht geschehen)
        this.$store.dispatch('manufacturerLoadAll');
        this.$store.dispatch('locationLoadAll');
        this.$store.dispatch('checkIntervalLoadAll');
        this.$store.dispatch('checkUtilLoadAll');
        this.$store.dispatch('calibrationRuleLoadAll');
        this.$store.dispatch('userLoadAll');

        // Daten holen
        this.machine = this.$store.getters.machineGetById(this.machineId);

        // Konnten die Daten erfolgreich geladen werden?
        if (this.machine !== undefined) {
            // Kopie ohne Reactivity erstellen
            this.machineEdit = Object.assign({}, this.machine);

            // Wenn kein Inhalt enthalten ist, aus dem NULL einen leeren String machen
            if (this.machineEdit.check_description === null) this.utilEdit.check_description = '';

            // Vue-Select reducer von Hand ausfuehren, da Vue-Select es nicht selber macht
            // wenn sein Model den Wert aendert -.-. Sonst gibt es Fehler im Backend ...
            for (let idx in this.machineEdit.check_utils) {
                this.machineEdit.check_utils[idx] = this.checkUtilReduce(this.machineEdit.check_utils[idx]);
            }

        } else {
            // Fehler
            this.$toast.open({
                message: 'Fehler beim laden der Daten!',
                type: 'error'
            });

            this.$router.back();
        }
    },

    methods: {
        // Store Actions
        ...mapActions({
            apiMachineEdit: 'machineEdit'
        }),

        // TinyMCE fuer "Wartungsarbeiten" ist initialisiert
        onCheckDescriptionEditorInit() {
            this.compLoaded = true;
        },

        // Pruefhilfsmittel Helper-Funktionen
        checkUtilGetLabel(option) { return option.name; },
        checkUtilGetValue(option) { return option.id; },
        checkUtilReduce(option) { return option.id; },

        // "Speichern" Button
        onSaveClick() {
            // Werte holen
            let valueName = this.machineEdit.name;
            let valueManufacturer = this.machineEdit.manufacturer;
            let valueDeviceNumber = this.machineEdit.device_number;
            let valueRegistrationNumber = this.machineEdit.registration_number;
            let valueLocation = this.machineEdit.location;
            let valueAcquisitionYear = this.machineEdit.acquisition_year;
            let valueMeasurementVariable = this.machineEdit.measurement_variable;
            let valueTolerance = this.machineEdit.tolerance;
            let valueMeasurementRange = this.machineEdit.measurement_range;
            let valueCalibrationRule = this.machineEdit.calibration_rule;
            let valueMaintenanceInterval = this.machineEdit.maintenance_interval;
            let valueMaintainer = this.machineEdit.maintainer;
            let valueCheckDescription = this.machineEdit.check_description;
            let valueCheckUtils = this.machineEdit.check_utils;

            // Pflicht-Felder pruefen
            if (
                valueName === ''
                || valueManufacturer === 0
                || valueRegistrationNumber === ''
                || valueLocation === 0
                || valueCalibrationRule === 0
                || valueMaintenanceInterval === 0
                || valueMaintainer === 0
            ) {
                this.$toast.open({
                    message: 'Bitte alle Pflicht-Felder ausfüllen',
                    type: 'warning'
                });

                return;
            }

            // Wenn kein Inhalt enthalten ist, aus dem NULL einen leeren String machen
            if (valueCheckDescription === null) valueCheckDescription = '';

            // Nur den Body-Inhalt verwenden
            if (valueCheckDescription !== '') {
                if (valueCheckDescription.includes('</body>')) {
                    valueCheckDescription = this.$regex.replace(valueCheckDescription, this.$utils.tinyMceContentRegex, '$1');
                }
            }

            // Payload erstellen
            let payload = {
                name: valueName,
                manufacturer: valueManufacturer,
                device_number: valueDeviceNumber,
                registration_number: valueRegistrationNumber,
                location: valueLocation,
                acquisition_year: valueAcquisitionYear,
                measurement_variable: valueMeasurementVariable,
                tolerance: valueTolerance,
                measurement_range: valueMeasurementRange,
                calibration_rule: valueCalibrationRule,
                maintenance_interval: valueMaintenanceInterval,
                maintainer: valueMaintainer,
                check_description: valueCheckDescription,
                check_utils: valueCheckUtils
            };

            // Anfrage ans Backend schicken
            this.apiMachineEdit({ id: this.machineId, data: payload }).then(result => {
                // Erfolgreich
                this.$toast.open({
                    message: 'Änderungen erfolgreich gespeichert',
                    type: 'success'
                });

                this.$router.back();

            }).catch(error => {
                // Fehler
                this.$toast.open({
                    message: 'Fehler beim Speichern der Änderungen!',
                    type: 'error'
                });

            });
        },

        // "Abbrechen" Button
        onCancelClick() {
            this.$router.back();
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
