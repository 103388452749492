<template>
    <form action="#" class="comp-root" :class="{ 'comp-is-loading': !this.compLoaded }">
        <div class="blockquote">
            <h1 class="mb-0">Maschinen</h1>
            <footer class="blockquote-footer">Maschine anlegen</footer>
        </div>

        <div class="row">
            <div class="col-6">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            Name/Bezeichnung*
                        </span>
                    </div>

                    <input
                        ref="name"
                        type="text"
                        :id="this._uid + 'name'"
                        class="form-control"
                        autocomplete="off"
                        v-model="name"
                    />
                </div>

                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            Ger&auml;te-Nummer
                        </span>
                    </div>

                    <input
                        ref="deviceNumber"
                        type="text"
                        :id="this._uid + 'device_number'"
                        class="form-control"
                        autocomplete="off"
                        v-model="device_number"
                    />
                </div>

                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            Standort*
                        </span>
                    </div>

                    <select
                        ref="location"
                        class="form-control"
                        :id="this._uid + 'location'"
                        v-model.number="location"
                    >
                        <option value="0">-</option>
                        <option v-for="option in this.$store.state.locations" :value="option.id">{{ option.name }}</option>
                    </select>
                </div>

                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            Messgr&ouml;&szlig;e(n)
                        </span>
                    </div>

                    <input
                        ref="measurementVariable"
                        type="text"
                        :id="this._uid + 'measurement_variable'"
                        class="form-control"
                        autocomplete="off"
                        v-model="measurement_variable"
                    />
                </div>

                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            Messbereich
                        </span>
                    </div>

                    <input
                        ref="measurementRange"
                        type="text"
                        :id="this._uid + 'measurement_range'"
                        class="form-control"
                        autocomplete="off"
                        v-model="measurement_range"
                    />
                </div>

                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            Wartungsfrist*
                        </span>
                    </div>

                    <select
                        ref="maintenanceInterval"
                        class="form-control"
                        :id="this._uid + 'maintenance_interval'"
                        v-model.number="maintenance_interval"
                    >
                        <option value="0">-</option>
                        <option v-for="option in this.$store.state.checkIntervals" :value="option.id">{{ option.name }}</option>
                    </select>
                </div>
            </div>

            <div class="col-6">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            Hersteller*
                        </span>
                    </div>

                    <select
                        ref="manufacturer"
                        class="form-control"
                        :id="this._uid + 'manufacturer'"
                        v-model.number="manufacturer"
                    >
                        <option value="0">-</option>
                        <option v-for="option in this.$store.state.manufacturer" :value="option.id">{{ option.name }}</option>
                    </select>
                </div>

                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            Registrier-Nr.*
                        </span>
                    </div>

                    <input
                        ref="registrationNumber"
                        type="text"
                        :id="this._uid + 'registration_number'"
                        class="form-control"
                        autocomplete="off"
                        title="Darauf achten, das die Registrier-Nr. einmalig ist!"
                        v-model="registration_number"
                    />
                </div>

                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            Anschaffungsjahr
                        </span>
                    </div>

                    <input
                        ref="acquisitionYear"
                        type="number"
                        :id="this._uid + 'acquisition_year'"
                        class="form-control"
                        autocomplete="off"
                        min="1900"
                        max="2100"
                        v-model="acquisition_year"
                    />
                </div>

                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            Toleranz
                        </span>
                    </div>

                    <input
                        ref="tolerance"
                        type="text"
                        :id="this._uid + 'tolerance'"
                        class="form-control"
                        autocomplete="off"
                        v-model="tolerance"
                    />
                </div>

                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            Kalibriervorschrift*
                        </span>
                    </div>

                    <select
                        ref="calibrationRule"
                        class="form-control"
                        :id="this._uid + 'calibration_rule'"
                        v-model.number="calibration_rule"
                    >
                        <option value="0">-</option>
                        <option v-for="option in this.$store.state.calibrationRules" :value="option.id">{{ option.name }}</option>
                    </select>
                </div>

                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            Verantwortlicher*
                        </span>
                    </div>

                    <select
                        ref="maintainer"
                        class="form-control"
                        :id="this._uid + 'maintainer'"
                        v-model.number="maintainer"
                    >
                        <option value="0">-</option>
                        <option v-for="option in this.$store.state.users" :value="option.id">{{ option.name }}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" style="align-items: flex-start">
                            Wartungsarbeiten*
                        </span>
                    </div>

                    <vue-mce
                        class="form-control tinymce-size-medium"
                        :config="this.$utils.tinyMceConfig"
                        v-model="check_description"
                        @init="onCheckDescriptionEditorInit"
                    />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            Pr&uuml;fhilfsmittel
                        </span>
                    </div>

                    <v-select
                        class="form-control"
                        v-model="check_utils"
                        :options="this.$store.state.checkUtils"
                        :multiple="true"
                        :getOptionLabel="checkUtilGetLabel"
                        :getOptionKey="checkUtilGetValue"
                        :reduce="checkUtilReduce"
                    ></v-select>
                </div>
            </div>
        </div>

        <button type="button" class="btn btn-primary" @click="onSaveClick">
            <i class="fas fa-save"></i> Anlegen
        </button>

        <button type="button" class="btn btn-secondary" @click="onCancelClick">
            <i class="fas fa-undo"></i> Abbrechen
        </button>
    </form>
</template>

<script lang="ts">
import {mapActions} from "vuex";

export default {
    name: 'machine-create',

    data() {
        return {
            // Ist die Komponente komplett geladen und bereit?
            compLoaded: false,

            // -------------------------------------- //

            // Name / Bezeichnung
            name: '',

            // Geraete-Nummer
            device_number: '',

            // Standort,
            location: 0,

            // Messgroessen
            measurement_variable: '',

            // Messbereich
            measurement_range: '',

            // Wartungsfrist
            maintenance_interval: 0,

            // Hersteller
            manufacturer: 0,

            // Registrier-Nr.
            registration_number: '',

            // Anschaffungsjahr
            acquisition_year: 0,

            // Toleranz
            tolerance: '',

            // Kalibriervorschrift
            calibration_rule: 0,

            // Verantwortlicher
            maintainer: 0,

            // Wartungsarbeiten
            check_description: '',

            // Pruefhilfsmittel
            check_utils: []
        };
    },

    async created() {
        // Daten holen (falls noch nicht geschehen)
        await this.$store.dispatch('manufacturerLoadAll');
        await this.$store.dispatch('locationLoadAll');
        await this.$store.dispatch('checkIntervalLoadAll');
        await this.$store.dispatch('checkUtilLoadAll');
        await this.$store.dispatch('calibrationRuleLoadAll');
        await this.$store.dispatch('userLoadAll');

        // Aktuelles Jahr vorausfuellen
        this.acquisition_year = (new Date()).getFullYear();
    },

    methods: {
        // Store Actions
        ...mapActions({
            apiMachineCreate: 'machineCreate'
        }),

        // TinyMCE fuer "Wartungsarbeiten" ist initialisiert
        onCheckDescriptionEditorInit() {
            this.compLoaded = true;
        },

        // Pruefhilfsmittel Helper-Funktionen
        checkUtilGetLabel(option) { return option.name; },
        checkUtilGetValue(option) { return option.id; },
        checkUtilReduce(option) { return option.id; },

        // "Speichern" Button
        onSaveClick() {
            // Werte holen
            let valueName = this.name;
            let valueManufacturer = this.manufacturer;
            let valueDeviceNumber = this.device_number;
            let valueRegistrationNumber = this.registration_number;
            let valueLocation = this.location;
            let valueAcquisitionYear = this.acquisition_year;
            let valueMeasurementVariable = this.measurement_variable;
            let valueTolerance = this.tolerance;
            let valueMeasurementRange = this.measurement_range;
            let valueCalibrationRule = this.calibration_rule;
            let valueMaintenanceInterval = this.maintenance_interval;
            let valueMaintainer = this.maintainer;
            let valueCheckDescription = this.check_description;
            let valueCheckUtils = this.check_utils;

            // Pflicht-Felder pruefen
            if (
                valueName === ''
                || valueManufacturer === 0
                || valueRegistrationNumber === ''
                || valueLocation === 0
                || valueCalibrationRule === 0
                || valueMaintenanceInterval === 0
                || valueMaintainer === 0
                || valueCheckDescription === ''
            ) {
                this.$toast.open({
                    message: 'Bitte alle Pflicht-Felder ausfüllen',
                    type: 'warning'
                });

                return;
            }

            // Wenn kein Inhalt enthalten ist, aus dem NULL einen leeren String machen
            if (valueCheckDescription === null) valueCheckDescription = '';

            // Nur den Body-Inhalt verwenden
            if (valueCheckDescription !== '') {
                if (valueCheckDescription.includes('</body>')) {
                    valueCheckDescription = this.$regex.replace(valueCheckDescription, this.$utils.tinyMceContentRegex, '$1');
                }
            }

            // Payload erstellen
            let payload = {
                name: valueName,
                manufacturer: valueManufacturer,
                device_number: valueDeviceNumber,
                registration_number: valueRegistrationNumber,
                location: valueLocation,
                acquisition_year: valueAcquisitionYear,
                measurement_variable: valueMeasurementVariable,
                tolerance: valueTolerance,
                measurement_range: valueMeasurementRange,
                calibration_rule: valueCalibrationRule,
                maintenance_interval: valueMaintenanceInterval,
                maintainer: valueMaintainer,
                check_description: valueCheckDescription,
                check_utils: valueCheckUtils
            };

            let regNumberCheck = this.$store.getters.machineGetByRegistrationNumber(valueRegistrationNumber);

            if (regNumberCheck !== null) {
                this.$toast.open({
                    message: 'Diese Registrier-Nr. ist schon vergeben!',
                    type: 'error'
                });

                return;
            }

            // Anfrage ans Backend schicken
            this.apiMachineCreate({ data: payload }).then(result => {
                // Erfolgreich
                this.$toast.open({
                    message: 'Maschine erfolgreich angelegt',
                    type: 'success'
                });

                this.$router.back();

            }).catch(error => {
                // Fehler
                this.$toast.open({
                    message: 'Fehler beim anlegen der Maschine!',
                    type: 'error'
                });

            });
        },

        // "Abbrechen" Button
        onCancelClick() {
            this.$router.back();
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
