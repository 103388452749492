<template>
</template>

<script lang="ts">
export default {
    name: 'auth-logout',

    mounted() {
        // Logout durchfuehren
        this.$http.get('logout').then(result => {
            // Informationen im Store updaten
            this.$store.commit('appSetLoggedIn', false);
            this.$store.commit('appSetUser', {});
            this.$store.commit('appSetBearerToken', '');

            // Zur Login-Seite leiten
            this.$router.push({
                name: 'auth_login',
                params: { previous: '/' }
            });
        }).catch(error => {

        });
    }
};
</script>

<style lang="scss" scoped>
</style>
