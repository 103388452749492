<template>
    <div class="comp-root" :class="{ 'comp-is-loading': !this.compLoaded }">
        <div class="blockquote">
            <h1 class="mb-0">Pr&uuml;fhilfsmittel</h1>
            <footer class="blockquote-footer">Pr&uuml;fhilfsmittel "{{ util.name }}" l&ouml;schen</footer>
        </div>

        <div class="row">
            <div class="col-6">
                Soll das Pr&uuml;fhilfsmittel "<b>{{ util.name }}</b>" wirklich gel&ouml;scht werden?<br />
                <br />

                <button type="button" class="btn btn-danger" @click="onDeleteClick">
                    <i class="fas fa-trash-alt"></i> L&ouml;schen
                </button>

                <button type="button" class="btn btn-secondary" @click="onCancelClick">
                    <i class="fas fa-undo"></i> Abbrechen
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { mapActions } from 'vuex';

export default {
    name: 'checkutil-delete',

    data() {
        return {
            // Ist die Komponente komplett geladen und bereit?
            compLoaded: false,

            // Datenbank-ID
            utilId: 0,

            // Pruefhilfsmittel
            util: {},
        };
    },

    created() {
        // ID aus Route auslesen
        this.utilId = parseInt(this.$route.params.id);

        // Daten holen
        this.util = this.$store.getters.checkUtilGetById(this.utilId);

        // Konnten die Daten erfolgreich geladen werden?
        if (this.util !== undefined) {
            this.compLoaded = true;

        } else {
            // Fehler
            this.$toast.open({
                message: 'Fehler beim laden der Daten!',
                type: 'error'
            });

            this.$router.back();
        }
    },

    methods: {
        // Store Actions
        ...mapActions({
            apiCheckUtilDelete: 'checkUtilDelete'
        }),

        // "Loeschen" Button
        onDeleteClick() {
            // Anfrage ans Backend schicken
            this.apiCheckUtilDelete({ id: this.utilId }).then(result => {
                // Erfolgreich
                this.$toast.open({
                    message: 'Prüfhilfsmittel erfolgreich gelöscht',
                    type: 'success'
                });

                this.$router.back();

            }).catch(error => {
                // Fehler
                this.$toast.open({
                    message: 'Fehler beim löschen des Prüfhilfsmittels!',
                    type: 'error'
                });

            });
        },

        // "Abbrechen" Button
        onCancelClick() {
            this.$router.back();
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
