import Vuex from 'vuex';

let store = new Vuex.Store({
    state: {
        app: {
            // Hat sich der Benutzer angemeldet?
            logged_in: false,

            // Aktueller User,
            user: {},

            // Der Bearer Token des Benutzers (sofern angemeldet)
            bearer_token: ''
        },

        // Liste aller Maschinen
        machines: [],

        // Liste aller Standorte
        locations: [],

        // Liste aller Wartungsfristen
        checkIntervals: [],

        // Liste aller Pruefhilfsmittel
        checkUtils: [],

        // Liste aller Kalibriervorschriften
        calibrationRules: [],

        // Liste aller Hersteller
        manufacturer: [],

        // Liste aller Benutzer
        users: []
    },

    mutations: {
        storeLoad(state, newState) {
            this.replaceState(Object.assign(state, newState));
        },

        appSetLoggedIn(state, status) {
            state.app.logged_in = status;
        },

        appSetUser(state, data) {
            state.app.user = data;
        },

        appSetBearerToken(state, token) {
            state.app.bearer_token = token;
        },

        // Maschinen
        machineLoadAll(state, list) {
            state.machines = list;
        },

        // Standorte,
        locationLoadAll(state, list) {
            state.locations = list;
        },

        // Wartungsfristen
        checkIntervalLoadAll(state, list) {
            state.checkIntervals = list;
        },

        // Pruefhilfsmittel
        checkUtilLoadAll(state, list) {
            state.checkUtils = list;
        },

        // Kalibriervorschriften
        calibrationRuleLoadAll(state, list) {
            state.calibrationRules = list;
        },

        // Hersteller
        manufacturerLoadAll(state, list) {
            state.manufacturer = list;
        },

        // Benutzer
        userLoadAll(state, list) {
            state.users = list;
        }
    },

    getters: {
        // Maschinen
        machineGetById: (state) => (id) => {
            let machine = state.machines.find(machine => machine.id === id);
            return typeof machine !== 'undefined' ? machine : null;
        },

        machineGetByRegistrationNumber: (state) => (regNumber) => {
            let machine = state.machines.find(machine => machine.registration_number === regNumber);
            return typeof machine !== 'undefined' ? machine : null;
        },

        // Standorte
        locationGetById: (state) => (id) => {
            let location = state.locations.find(loc => loc.id === id);
            return typeof location !== 'undefined' ? location : null;
        },

        // Wartungsfristen
        checkIntervalGetById: (state) => (id) => {
            let checkInterval = state.checkIntervals.find(intv => intv.id === id);
            return typeof checkInterval !== 'undefined' ? checkInterval : null;
        },

        // Pruefhilfsittel
        checkUtilGetById: (state) => (id) => {
            let checkUtil = state.checkUtils.find(util => util.id === id);
            return typeof checkUtil !== 'undefined' ? checkUtil : null;
        },

        // Kalibriervorschriften
        calibrationRuleGetById: (state) => (id) => {
            let calibrationRule = state.calibrationRules.find(rule => rule.id === id);
            return typeof calibrationRule !== 'undefined' ? calibrationRule : null;
        },

        // Hersteller
        manufacturerGetById: (state) => (id) => {
            let manufacturer = state.manufacturer.find(manufacturer => manufacturer.id === id);
            return typeof manufacturer !== 'undefined' ? manufacturer : null;
        },

        // Benutzer
        userGetById: (state) => (id) => {
            let user = state.users.find(user => user.id === id);
            return typeof user !== 'undefined' ? user : null;
        }
    },

    actions: {
        // Allgemein / Store
        storeInit({ commit }) {
            // Wenn wir etwas im localStorage haben, diesen benutzen
            // let content = localStorage.getItem('store');

            // if (content) {
            //     commit('storeLoad', JSON.parse(content));
            // }
        },

        // Maschinen
        machineLoadAll({ commit }, forceReload = false) {
            return new Promise((resolve, reject) => {
                if (this.state.machines.length === 0 || forceReload) {
                    this.$http.get('machine').then(result => {
                        commit('machineLoadAll', result.data);
                        resolve();
                    }).catch(reject);
                } else {
                    resolve();
                }
            });
        },

        machineCreate({ commit, dispatch }, { data }) {
            return new Promise((resolve, reject) => {
                this.$http.post('machine', data).then(result => {
                    dispatch('machineLoadAll')
                        .then(resolve2 => resolve(result))
                        .catch(reject2 => reject);

                    resolve(result);
                }).catch(reject);
            });
        },

        machineMarkMaintenanceAsDone({ commit, dispatch }, { id }) {
            return new Promise((resolve, reject) => {
                this.$http.get('machine/mark-maintenance-as-done/' + id).then(result => {
                    dispatch('machineLoadAll')
                        .then(resolve2 => resolve(result))
                        .catch(reject2 => reject);

                    resolve(result);
                }).catch(reject);
            });
        },

        machineEdit({ commit, dispatch }, { id, data }) {
            return new Promise((resolve, reject) => {
                this.$http.put('machine/' + id, data).then(result => {
                    dispatch('machineLoadAll')
                        .then(resolve2 => resolve(result))
                        .catch(reject2 => reject);

                    resolve(result);
                }).catch(reject);
            });
        },

        machineDelete({ commit, dispatch }, { id }) {
            return new Promise((resolve, reject) => {
                this.$http.delete('machine/' + id).then(result => {
                    dispatch('machineLoadAll')
                        .then(resolve2 => resolve(result))
                        .catch(reject2 => reject);

                    resolve(result);
                }).catch(reject);
            });
        },

        machineTaskGetByMachineId({ commit }, { id }) {
            return new Promise((resolve, reject) => {
                this.$http.get('machine-task/' + id).then(result => {
                    resolve(result);
                }).catch(reject);
            });
        },

        machineTaskCreate({ commit }, { data }) {
            return new Promise((resolve, reject) => {
                this.$http.post('machine-task', data).then(result => {
                    resolve(result);
                }).catch(reject);
            });
        },

        machineTaskEdit({ commit }, { id, data }) {
            return new Promise((resolve, reject) => {
                this.$http.put('machine-task/' + id, data).then(result => {
                    resolve(result);
                }).catch(reject);
            });
        },

        machineTaskDelete({ commit }, { id }) {
            return new Promise((resolve, reject) => {
                this.$http.delete('machine-task/' + id).then(result => {
                    resolve(result);
                }).catch(reject);
            });
        },

        // Standorte
        locationLoadAll({ commit }, forceReload = false) {
            return new Promise((resolve, reject) => {
                if (this.state.locations.length === 0 || forceReload) {
                    this.$http.get('location').then(result => {
                        commit('locationLoadAll', result.data);
                        resolve();
                    }).catch(reject);
                } else {
                    resolve();
                }
            });
        },

        locationCreate({ commit, dispatch }, { data }) {
            return new Promise((resolve, reject) => {
                this.$http.post('location', data).then(result => {
                    dispatch('locationLoadAll')
                        .then(resolve2 => resolve(result))
                        .catch(reject2 => reject);

                    resolve(result);
                }).catch(reject);
            });
        },

        locationEdit({ commit, dispatch }, { id, data }) {
            return new Promise((resolve, reject) => {
                this.$http.put('location/' + id, data).then(result => {
                    dispatch('locationLoadAll')
                        .then(resolve2 => resolve(result))
                        .catch(reject2 => reject);

                    resolve(result);
                }).catch(reject);
            });
        },

        locationDelete({ commit, dispatch }, { id }) {
            return new Promise((resolve, reject) => {
                this.$http.delete('location/' + id).then(result => {
                    dispatch('locationLoadAll')
                        .then(resolve2 => resolve(result))
                        .catch(reject2 => reject);

                    resolve(result);
                }).catch(reject);
            });
        },

        // Wartungsfristen
        checkIntervalLoadAll({ commit }, forceReload = false) {
            return new Promise((resolve, reject) => {
                if (this.state.checkIntervals.length === 0 || forceReload) {
                    this.$http.get('check-interval').then(result => {
                        commit('checkIntervalLoadAll', result.data);
                        resolve();
                    }).catch(reject);
                } else {
                    resolve();
                }
            });
        },

        checkIntervalCreate({ commit, dispatch }, { data }) {
            return new Promise((resolve, reject) => {
                this.$http.post('check-interval', data).then(result => {
                    dispatch('checkIntervalLoadAll')
                        .then(resolve2 => resolve(result))
                        .catch(reject2 => reject);

                    resolve(result);
                }).catch(reject);
            });
        },

        checkIntervalEdit({ commit, dispatch }, { id, data }) {
            return new Promise((resolve, reject) => {
                this.$http.put('check-interval/' + id, data).then(result => {
                    dispatch('checkIntervalLoadAll')
                        .then(resolve2 => resolve(result))
                        .catch(reject2 => reject);

                    resolve(result);
                }).catch(reject);
            });
        },

        checkIntervalDelete({ commit, dispatch }, { id }) {
            return new Promise((resolve, reject) => {
                this.$http.delete('check-interval/' + id).then(result => {
                    dispatch('checkIntervalLoadAll')
                        .then(resolve2 => resolve(result))
                        .catch(reject2 => reject);

                    resolve(result);
                }).catch(reject);
            });
        },

        // Pruefhilfsmittel
        checkUtilLoadAll({ commit }, forceReload = false) {
            return new Promise((resolve, reject) => {
                if (this.state.checkUtils.length === 0 || forceReload) {
                    this.$http.get('check-util').then(result => {
                        commit('checkUtilLoadAll', result.data);
                        resolve();
                    }).catch(reject);
                } else {
                    resolve();
                }
            });
        },

        checkUtilCreate({ commit, dispatch }, { data }) {
            return new Promise((resolve, reject) => {
                this.$http.post('check-util', data).then(result => {
                    dispatch('checkUtilLoadAll')
                        .then(resolve2 => resolve(result))
                        .catch(reject2 => reject);

                    resolve(result);
                }).catch(reject);
            });
        },

        checkUtilEdit({ commit, dispatch }, { id, data }) {
            return new Promise((resolve, reject) => {
                this.$http.put('check-util/' + id, data).then(result => {
                    dispatch('checkUtilLoadAll')
                        .then(resolve2 => resolve(result))
                        .catch(reject2 => reject);

                    resolve(result);
                }).catch(reject);
            });
        },

        checkUtilDelete({ commit, dispatch }, { id }) {
            return new Promise((resolve, reject) => {
                this.$http.delete('check-util/' + id).then(result => {
                    dispatch('checkUtilLoadAll')
                        .then(resolve2 => resolve(result))
                        .catch(reject2 => reject);

                    resolve(result);
                }).catch(reject);
            });
        },

        // Kalibriervorschriften
        calibrationRuleLoadAll({ commit }, forceReload = false) {
            return new Promise((resolve, reject) => {
                if (this.state.calibrationRules.length === 0 || forceReload) {
                    this.$http.get('calibration-rule').then(result => {
                        commit('calibrationRuleLoadAll', result.data);
                        resolve();
                    }).catch(reject);
                } else {
                    resolve();
                }
            });
        },

        calibrationRuleCreate({ commit, dispatch }, { data }) {
            return new Promise((resolve, reject) => {
                this.$http.post('calibration-rule', data).then(result => {
                    dispatch('calibrationRuleLoadAll')
                        .then(resolve2 => resolve(result))
                        .catch(reject2 => reject);

                    resolve(result);
                }).catch(reject);
            });
        },

        calibrationRuleEdit({ commit, dispatch }, { id, data }) {
            return new Promise((resolve, reject) => {
                this.$http.put('calibration-rule/' + id, data).then(result => {
                    dispatch('calibrationRuleLoadAll')
                        .then(resolve2 => resolve(result))
                        .catch(reject2 => reject);

                    resolve(result);
                }).catch(reject);
            });
        },

        calibrationRuleDelete({ commit, dispatch }, { id }) {
            return new Promise((resolve, reject) => {
                this.$http.delete('calibration-rule/' + id).then(result => {
                    dispatch('calibrationRuleLoadAll')
                        .then(resolve2 => resolve(result))
                        .catch(reject2 => reject);

                    resolve(result);
                }).catch(reject);
            });
        },

        // Hersteller
        manufacturerLoadAll({ commit }, forceReload = false) {
            return new Promise((resolve, reject) => {
                if (this.state.manufacturer.length === 0 || forceReload) {
                    this.$http.get('manufacturer').then(result => {
                        commit('manufacturerLoadAll', result.data);
                        resolve();
                    }).catch(reject);
                } else {
                    resolve();
                }
            });
        },

        manufacturerCreate({ commit, dispatch }, { data }) {
            return new Promise((resolve, reject) => {
                this.$http.post('manufacturer', data).then(result => {
                    dispatch('manufacturerLoadAll')
                        .then(resolve2 => resolve(result))
                        .catch(reject2 => reject);

                    resolve(result);
                }).catch(reject);
            });
        },

        manufacturerEdit({ commit, dispatch }, { id, data }) {
            return new Promise((resolve, reject) => {
                this.$http.put('manufacturer/' + id, data).then(result => {
                    dispatch('manufacturerLoadAll')
                        .then(resolve2 => resolve(result))
                        .catch(reject2 => reject);

                    resolve(result);
                }).catch(reject);
            });
        },

        manufacturerDelete({ commit, dispatch }, { id }) {
            return new Promise((resolve, reject) => {
                this.$http.delete('manufacturer/' + id).then(result => {
                    dispatch('manufacturerLoadAll')
                        .then(resolve2 => resolve(result))
                        .catch(reject2 => reject);

                    resolve(result);
                }).catch(reject);
            });
        },

        // Benutzer
        userLoadAll({ commit }, forceReload = false) {
            return new Promise((resolve, reject) => {
                if (this.state.users.length === 0 || forceReload) {
                    this.$http.get('user').then(result => {
                        commit('userLoadAll', result.data);
                        resolve();
                    }).catch(reject);
                } else {
                    resolve();
                }
            });
        },
    }
});

export default store;
