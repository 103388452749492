<template>
    <div class="comp-root" :class="{ 'comp-is-loading': !this.compLoaded }">
        <div class="blockquote">
            <h1 class="mb-0">Hersteller</h1>
            <footer class="blockquote-footer">Hersteller "{{ manufacturer.name }}" l&ouml;schen</footer>
        </div>

        <div class="row">
            <div class="col-6">
                Soll der Hersteller "<b>{{ manufacturer.name }}</b>" wirklich gel&ouml;scht werden?<br />
                <br />

                <button type="button" class="btn btn-danger" @click="onDeleteClick">
                    <i class="fas fa-trash-alt"></i> L&ouml;schen
                </button>

                <button type="button" class="btn btn-secondary" @click="onCancelClick">
                    <i class="fas fa-undo"></i> Abbrechen
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { mapActions } from 'vuex';

export default {
    name: 'manufacturer-delete',

    data() {
        return {
            // Ist die Komponente komplett geladen und bereit?
            compLoaded: false,

            // Datenbank-ID
            manufacturerId: 0,

            // Hersteller
            manufacturer: {},
        };
    },

    created() {
        // ID aus Route auslesen
        this.manufacturerId = parseInt(this.$route.params.id);

        // Daten holen
        this.manufacturer = this.$store.getters.manufacturerGetById(this.manufacturerId);

        // Konnten die Daten erfolgreich geladen werden?
        if (this.manufacturer !== undefined) {
            this.compLoaded = true;

        } else {
            // Fehler
            this.$toast.open({
                message: 'Fehler beim laden der Daten!',
                type: 'error'
            });

            this.$router.back();
        }
    },

    methods: {
        // Store Actions
        ...mapActions({
            apiManufacturerDelete: 'manufacturerDelete'
        }),

        // "Loeschen" Button
        onDeleteClick() {
            // Anfrage ans Backend schicken
            this.apiManufacturerDelete({ id: this.manufacturerId }).then(result => {
                // Erfolgreich
                this.$toast.open({
                    message: 'Hersteller erfolgreich gelöscht',
                    type: 'success'
                });

                this.$router.back();
            }).catch(reject => {
                // Fehler
                this.$toast.open({
                    message: 'Fehler beim löschen des Herstellers!',
                    type: 'error'
                });
            });
        },

        // "Abbrechen" Button
        onCancelClick() {
            this.$router.back();
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
