// Imports
import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import VueAxios from "vue-axios";
import VueToast from 'vue-toast-notification';
import * as VueMoment from 'vue-moment';
import VueMce from 'vue-mce';
import VueSelect from 'vue-select'
import notie from 'notie';
import axios from 'axios';
import XRegExp from 'xregexp';
import * as $ from 'jquery';
import 'bootstrap';
import appUtils from './app_util';

// jQuery
Vue.prototype.$jquery = $;
Vue.prototype.$utils = appUtils();
Vue.prototype.$regex = XRegExp;

// Vue "Development mode" Hinweis deaktivieren
Vue.config.devtools = true; // true;
Vue.config.productionTip = false;

// Vue Router, Vuex
Vue.use(VueRouter);
Vue.use(Vuex);

// TinyMCE
Vue.use(VueMce);

// vue-select
Vue.component('v-select', VueSelect);

// Vue Toast
Vue.use(VueToast, {
    position: 'top-right',
    type: 'info',
    duration: 2500
});

// Vue Moment "installieren"
Vue.use(VueMoment);

// Store einbinden
let store = require('./store').default;

// VueAxios und axios
let axiosConfigured = axios.create({
    baseURL: document.getElementById('app').getAttribute('data-app-url') + 'api/',
    timeout: 5000
});

// Axios in Store einbinden
store.$http = axiosConfigured;

// Bei jedem Request, der von Axios aus gesendet wird, automatisch den Bearer Token anhaengen
axiosConfigured.interceptors.request.use(config => {
    let bearerToken = store.state.app.bearer_token;

    if (bearerToken !== '') {
        config.headers['Authorization'] = 'Bearer ' + bearerToken;
    }

    return config;
}, error => {
    return Promise.reject(error);
});

// Store initialisieren
store.dispatch('storeInit');

// Store bei Aenderungen im localStorage speichern
// store.subscribe((mutation, state) => {
//     localStorage.setItem('store', JSON.stringify(state));
// });

// Vue Axios "installieren"
Vue.use(VueAxios, axiosConfigured);

// Routes definieren
const routes = require('./routes').default;

// Router instanzieren
let router = new VueRouter({
    routes: routes,
    linkExactActiveClass: 'active'
});

// Router so "erweitern", das er fuer uns checkt, ob wir eingeloggt sind oder/nicht
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.need_login) && !store.state.app.logged_in) {
        next({
            name: 'auth_login',
            params: { previous: to.fullPath },
            replace: true
        });
    } else {
        // Braucht Entwickler-Bestaetigung
        if (to.matched.some(record => record.meta.is_dev)) {
            notie.input({
                text: 'Entwickler-Kennwort:',
                type: 'password',
                submitText: 'OK',
                cancelText: 'Abbrechen'
            }, function(value) {
                if (value === 'Start123') {
                    next();
                } else {
                    notie.alert({
                        type: 'error',
                        text: 'Falsches Kennwort!'
                    });
                }
            }, function(value) {
            });
        } else {
            next();
        }
    }
});

// App instanzieren
const app = new Vue({
    delimiters: ['({', '})'],

    router: router,
    store: store,
});

// ... und mounten
app.$mount('#app');

// Bootstrap Multi-Level Dropdown
// See https://stackoverflow.com/questions/44467377/bootstrap-4-multilevel-dropdown-inside-navigation
$('.dropdown-menu a.dropdown-toggle').on('click', function(clickEv) {
    if (!$(this).next().hasClass('show')) {
        $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
    }

    $(this).next('.dropdown-menu').toggleClass('show');

    $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(clickEv2) {
        $('.dropdown-submenu .show').removeClass('show');
    });

    return false;
});
