<template>
    <form action="#" class="comp-root">
        <div class="blockquote">
            <h1 class="mb-0">Anmelden</h1>
            <footer class="blockquote-footer">Bitte melden Sie sich mit ihrem Account an</footer>
        </div>

        <div class="row">
            <div class="col-6">
                <div class="form">
                    <div class="form-group">
                        <label :for="this._uid + 'username'">Benutzername</label>

                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="fas fa-user"></i>
                                </span>
                            </div>

                            <input ref='username' type="text" :id="this._uid + 'username'" class="form-control" name="username" autocomplete="username" />
                        </div>
                    </div>

                    <div class="form-group">
                        <label :for="this._uid + 'password'">Kennwort</label>

                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="fas fa-lock"></i>
                                </span>
                            </div>

                            <input ref='password' type="password" :id="this._uid + 'password'" class="form-control" name="password" autocomplete="current-password" />
                        </div>
                    </div>

                    <button type="button" class="btn btn-primary" @click="onLoginClick">Anmelden</button>
                    <a href="#" class="btn btn-outline-primary">Kennwort vergessen</a>
                </div>
            </div>
        </div>
    </form>
</template>

<script lang="ts">
export default {
    name: 'auth-login',

    methods: {
        onLoginClick() {
            // Felder-Werte ermitteln
            let username = this.$refs.username.value;
            let password = this.$refs.password.value;

            // Prüfen, ob die Felder leer sind
            if (username === '' || password === '') {
                this.$toast.open({
                    message: 'Bitte alle Pflicht-Felder ausfüllen',
                    type: 'warning'
                });

                return;
            }

            // Daten-Objekt zusammenfassen
            let payload = {
                username: username,
                password: password
            };

            // Login-Anfrage ans Backend schicken
            this.$http.post('login', payload).then(result => {
                // Wenn der Response-Code 200 ist, war der Login erfolgreich
                if (result.status === 200) {
                    // Informationen im Store updaten
                    this.$store.commit('appSetLoggedIn', true);
                    this.$store.commit('appSetUser', result.data.data);
                    this.$store.commit('appSetBearerToken', result.data.data.api_token);

                    // Zur vorherigen / gewuenschten Seite navigieren
                    this.$router.push({
                        path: this.$route.params.previous,
                        replace: true
                    });

                    this.$toast.open({
                        message: 'Erfolgreich eingeloggt!',
                        type: 'success'
                    });
                }
            }).catch(error => {
                // Login nicht erfolgreich
                if (error.response.status === 422) {
                    this.$toast.open({
                        message: 'Login nicht erfolgreich (falsche Zugangsdaten?)',
                        type: 'error'
                    });
                } else {
                    this.$toast.open({
                        message: 'Es ist ein Fehler beim Login aufgetreten!',
                        type: 'error'
                    });
                }
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.form {
    padding: 1rem;
    background-color: #DEDEDE;
    border-radius: 4px;
}
</style>
