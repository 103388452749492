<template>
    <h1>Entwickler Spielwiese</h1>
</template>

<script lang="ts">
export default {
    name: 'dev-test',

    created() {
        this.$http.get('machine/upcoming-maintenances').then(result => {
            console.log(result);
        }).catch(error => {
            console.log(error);
        });
    }
};
</script>

<style lang="scss" scoped>
</style>
