import XRegExp from 'xregexp';

export default () => {
    return {
        tinyMceConfig: {
            language: 'de',
            fontsize_formats: "8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 39px 34px 38px 42px 48px",
            plugins: 'print preview fullpage searchreplace autolink table fullscreen',
            toolbar1: 'fullscreen formatselect fontsizeselect | bold italic strikethrough forecolor backcolor link'
        },

        tinyMceContentRegex: XRegExp('(?:.*)\<body.*?\>(?:|\r|\n|\r\n){0,1}(.*?)(?:|\r|\n|\r\n){0,1}\<\/body\>(?:.*)', 'gms'),

        // /(?:.*)\<body.*?\>(?:|\r|\n|\r\n){0,1}(.*?)(?:|\r|\n|\r\n){0,1}\<\/body\>(?:.*)/gms

        /*
        let wrappedFn = this.$utils.debounceWrapper(function() {
            console.log('Done');
        }, 250);
         */
        debounceWrapper(fnFunction, waitTime, runImmediate) {
            let timeoutId;

            return function() {
                let fnContext = this, fnArgs = arguments;

                let runLater = function() {
                    timeoutId = null;
                    if (!runImmediate) fnFunction.apply(fnContext, fnArgs);
                };

                let callNow = runImmediate && !timeoutId;

                clearTimeout(timeoutId);
                timeoutId = setTimeout(runLater, waitTime);

                if (callNow) fnFunction.apply(fnContext, fnArgs);
            };
        }
    };
};
