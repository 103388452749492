<template>
    <div class="comp-root" :class="{ 'comp-is-loading': !this.compLoaded }">
        <div class="blockquote">
            <h1 class="mb-0">Wartungsfristen</h1>
            <footer class="blockquote-footer">Eine Liste aller vorhandenen Wartungsfristen</footer>
        </div>

        <table class="table table-hover table-striped">
            <thead class="thead-dark">
                <tr>
                    <th title="ID">#</th>
                    <th>Frist</th>
                    <th>Erstellt am</th>
                    <th>Letzte Aktualisierung am</th>
                    <th>Aktion</th>
                </tr>
            </thead>
            <tbody v-show="this.$store.state.checkIntervals.length === 0">
                <tr>
                    <td colspan="5">
                        Es wurden keine Wartungsfristen gefunden!
                    </td>
                </tr>
            </tbody>
            <tbody>
                <tr v-for="interval in this.$store.state.checkIntervals">
                    <td>
                        #{{ interval.id }}
                    </td>
                    <td>
                        <b>{{ interval.name }}</b>
                    </td>
                    <td>
                        {{ interval.created_at | moment("DD.MM.YYYY - HH:mm") }}
                    </td>
                    <td>
                        {{ interval.updated_at | moment("DD.MM.YYYY - HH:mm") }}
                    </td>
                    <td>
                        <router-link
                            :to="{ name: 'checkinterval_edit', params: { id: interval.id } }"
                            class="btn btn-sm btn-primary"
                        >Bearbeiten</router-link>

                        <router-link
                            :to="{ name: 'checkinterval_delete', params: { id: interval.id } }"
                            class="btn btn-sm btn-danger"
                        >L&ouml;schen</router-link>
                    </td>
                </tr>
            </tbody>
        </table>

        <router-link :to="{ name: 'checkinterval_create' }" class="btn btn-success">
            <i class="fas fa-plus"></i> Wartungsfrist anlegen
        </router-link>
    </div>
</template>

<script lang="ts">
import { mapActions } from 'vuex';

export default {
    name: 'checkinterval-list',

    data() {
        return {
            // Ist die Komponente komplett geladen und bereit?
            compLoaded: false
        };
    },

    created() {
        // Liste aller Wartungsfristen laden
        this.apiCheckIntervalLoadAll(true).then(result => {
            this.compLoaded = true;

        }).catch(error => {
            this.$toast.open({
                message: 'Fehler beim laden der Daten!',
                type: 'error'
            });
        });
    },

    methods: {
        // Store Actions
        ...mapActions({
            apiCheckIntervalLoadAll: 'checkIntervalLoadAll'
        })
    }
};
</script>

<style lang="scss" scoped>
</style>
