<template>
    <form action="#" class="comp-root" :class="{ 'comp-is-loading': !this.compLoaded }">
        <div class="blockquote">
            <h1 class="mb-0">Kalibriervorschriften</h1>
            <footer class="blockquote-footer">Kalibriervorschrift anlegen</footer>
        </div>

        <div class="row">
            <div class="col-9">
                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                Name*
                            </span>
                        </div>

                        <input
                            ref='name'
                            type="text"
                            :id="this._uid + 'name'"
                            class="form-control"
                            autocomplete="off"
                            v-model="name"
                        />
                    </div>
                </div>

                Beschreibung:<br />
                <vue-mce :config="this.$utils.tinyMceConfig" v-model="description" @init="onDescriptionEditorInit" />

                <button type="button" class="btn btn-primary" @click="onSaveClick">
                    <i class="fas fa-save"></i> Anlegen
                </button>

                <button type="button" class="btn btn-secondary" @click="onCancelClick">
                    <i class="fas fa-undo"></i> Abbrechen
                </button>
            </div>
        </div>
    </form>
</template>

<script lang="ts">
import { mapActions } from 'vuex';

export default {
    name: 'calibrationrule-create',

    data() {
        return {
            // Ist die Komponente komplett geladen und bereit?
            compLoaded: false,

            // Name
            name: '',

            // Beschreibung
            description: ''
        };
    },

    methods: {
        // Store Actions
        ...mapActions({
            apiCalibrationRuleCreate: 'calibrationRuleCreate'
        }),

        // TinyMCE fuer die Beschreibung ist initialisiert
        onDescriptionEditorInit() {
            this.compLoaded = true;
        },

        // "Speichern" Button
        onSaveClick() {
            // Werte holen
            let valueName = this.name;
            let valueDescription = this.description;

            // Pflicht-Felder pruefen
            if (valueName === '') {
                this.$toast.open({
                    message: 'Bitte alle Pflicht-Felder ausfüllen',
                    type: 'warning'
                });

                return;
            }

            // Wenn kein Inhalt enthalten ist, aus dem NULL einen leeren String machen
            if (valueDescription === null) valueDescription = '';

            // Nur den Body-Inhalt verwenden
            if (valueDescription !== '') {
                if (valueDescription.includes('</body>')) {
                    valueDescription = this.$regex.replace(valueDescription, this.$utils.tinyMceContentRegex, '$1');
                }
            }

            // Payload erstellen
            let payload = {
                name: valueName,
                description: valueDescription
            };

            // Anfrage ans Backend schicken
            this.apiCalibrationRuleCreate({ data: payload }).then(result => {
                // Erfolgreich
                this.$toast.open({
                    message: 'Kalibriervorschrift erfolgreich angelegt',
                    type: 'success'
                });

                this.$router.back();

            }).catch(error => {
                // Fehler
                this.$toast.open({
                    message: 'Fehler beim anlegen der Kalibriervorschrift!',
                    type: 'error'
                });

            });
        },

        // "Abbrechen" Button
        onCancelClick() {
            this.$router.back();
        }
    }
};
</script>

<style lang="scss" scoped>
.tinymce {
    height: 300px !important;
    margin-bottom: 1rem;
}
</style>
