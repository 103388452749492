<template>
    <form action="#" class="comp-root" :class="{ 'comp-is-loading': !this.compLoaded }">
        <div class="blockquote">
            <h1 class="mb-0">Wartungsfristen</h1>
            <footer class="blockquote-footer">Wartungsfrist "{{ interval.name }}" bearbeiten</footer>
        </div>

        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                Name*
                            </span>
                        </div>

                        <input
                            ref='name'
                            type="text"
                            :id="this._uid + 'name'"
                            class="form-control"
                            autocomplete="off"
                            v-model="intervalEdit.name"
                        />
                    </div>
                </div>

                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                Frist*
                            </span>
                        </div>

                        <input
                            ref='interval'
                            type="text"
                            :id="this._uid + 'interval'"
                            class="form-control"
                            autocomplete="off"
                            v-model="intervalEdit.interval"
                        />

                        <div class="input-group-append">
                            <span class="input-group-text">
                                <a target="_blank" title="Im Crontab-Format" href="https://crontab.guru/">
                                    <i class="fas fa-info-circle" />
                                </a>
                            </span>
                        </div>
                    </div>
                </div>

                <button type="button" class="btn btn-primary" @click="onSaveClick">
                    <i class="fas fa-save"></i> Speichern
                </button>

                <button type="button" class="btn btn-secondary" @click="onCancelClick">
                    <i class="fas fa-undo"></i> Abbrechen
                </button>
            </div>
        </div>
    </form>
</template>

<script lang="ts">
import { mapActions } from 'vuex';

export default {
    name: 'checkinterval-edit',

    data() {
        return {
            // Ist die Komponente komplett geladen und bereit?
            compLoaded: false,

            // ID der Wartungsfrist
            intervalId: 0,

            // Originale Version
            interval: {},

            // Bearbeitete Version
            intervalEdit: {},
        };
    },

    created() {
        // ID aus Route auslesen
        this.intervalId = parseInt(this.$route.params.id);

        // Daten holen
        this.interval = this.$store.getters.checkIntervalGetById(this.intervalId);

        // Konnten die Daten erfolgreich geladen werden?
        if (this.interval !== undefined) {
            // Kopie ohne Reactivity erstellen
            this.intervalEdit = Object.assign({}, this.interval);

            this.compLoaded = true;

        } else {
            // Fehler
            this.$toast.open({
                message: 'Fehler beim laden der Daten!',
                type: 'error'
            });

            this.$router.back();
        }
    },

    methods: {
        // Store Actions
        ...mapActions({
            apiCheckIntervalEdit: 'checkIntervalEdit'
        }),

        // "Speichern" Button
        onSaveClick() {
            // Werte holen
            let valueName = this.intervalEdit.name;
            let valueInterval = this.intervalEdit.interval;

            // Pflicht-Felder pruefen
            if (valueName === '' || valueInterval === '') {
                this.$toast.open({
                    message: 'Bitte alle Pflicht-Felder ausfüllen',
                    type: 'warning'
                });

                return;
            }

            // Payload erstellen
            let payload = {
                name: valueName,
                interval: valueInterval
            };

            // Anfrage ans Backend schicken
            this.apiCheckIntervalEdit({ id: this.intervalId, data: payload }).then(result => {
                // Erfolgreich
                this.$toast.open({
                    message: 'Änderungen erfolgreich gespeichert',
                    type: 'success'
                });

                this.$router.back();

            }).catch(error => {
                // Fehler
                this.$toast.open({
                    message: 'Fehler beim Speichern der Änderungen!',
                    type: 'error'
                });

            });
        },

        // "Abbrechen" Button
        onCancelClick() {
            this.$router.back();
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
